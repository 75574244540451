<template>
	<div>
		<van-nav-bar
			title="評估結果"
			left-arrow
			fixed
			:z-index="1000"
			class="qjc-nav-bar"
			@click-left="$router.back()"
		>
			<van-image 
				:src="require('@/assets/images/home.png')" 
				slot="right"
				width="0.32rem"
				height="0.32rem"
				style="vertical-align: middle;"
				@click="$router.push('/')"
			/>
		</van-nav-bar>
		
		<div class="result">
			<div class="branch_wrap">
				<div class="branch_score">
					<div class="branch_content">
						<div class="branch_item_title">
							健康評分
							<i @click="scoreTip1 = !scoreTip1" class="iconfont iconyiwen1 z_index9 qjc-relative">
								<div v-if="scoreTip1" class="score_tip_box qjc-bg-fff qjc-absolute">
									<span class="score_tip_text">米因以大數據為基礎，綜合個人的當前健康狀況、潜在疾病風險和未來健康風險進行整體評分，給出百分制的「健康分」，以求盡可能對整體健康進行量化評估，既可用於表示個人健康狀況，也可作為個人健康管理的目標和評估標準。
									<br><span class="score_tip_span">对于个人而言，分數越高則相對越健康，健康分不適用於85歲以上的長者。</span></span>
									<div class="arrow"></div>
								</div>
							</i>
						</div>
						<!-- <div class="to_update" v-if="res.c == 0" @click="lookMore">個人資訊 ></div> -->
						<div class="score_info_top" :style="{'--infoLeft': scoreVal > 3 ? scoreVal - 3 + '%' : scoreVal + 2 + '%' }">
							<div class="">
								<div class=""> {{res.base.age > 85 ? '健康分不適用於85歲以上的長者' : '您的健康評分'}}</div>
								<div class="score_info_top_info" v-if="res.base.age <= 85">
									<span :class="scoreVal <= 59 ? 'score_color3 score_val' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_val' : 'score_color1 score_val' ">{{scoreVal}}</span><span :class="scoreVal <= 59 ? 'score_color3 score_unit' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_unit' : 'score_color1 score_unit' ">
									分({{scoreVal <= 59 ? '欠佳' : (scoreVal > 59 && scoreVal <= 89) ? '良好' : scoreVal > 89 ? '優等' : ''}})</span>
									<img v-if="scoreVal <= 59" class="xiao_icon" src="@/assets/images/health_3.png" />
									<img v-if="scoreVal > 59 && scoreVal <= 89" class="xiao_icon" src="@/assets/images/health_2.png" />
									<img v-if="scoreVal > 89" class="xiao_icon" src="@/assets/images/health_1.png" />
								</div>
							</div>
							<div class="score_info_top_btn" v-if="res.base.age <= 85" @click="clickCheckModule('branch')">前往查看</div>
						</div>
						<div class="score_line">
							<div class="score_one_bg"></div>
							<div class="score_d1"></div>
							<div class="score_two_bg"></div>
							<div class="score_d2"></div>
							<div class="score_three_bg"></div>
							<div class="score_line_top">
								<div class="score_one" :style="{width: (scoreVal <=59 ? scoreVal + '%' : '59%')}"></div>
								<div v-if="scoreVal > 59" class="score_two" :style="{width: ((scoreVal > 59 && scoreVal <= 89) ? scoreVal - 59 + '%' : '30%')}"></div>
								<div v-if="scoreVal > 89"  class="score_three" :style="{width: (scoreVal > 89 ? scoreVal - 89 + '%' : '11%')}"></div>
								<div :class="scoreVal <=59 ? 'score_circle3' : (scoreVal > 59 && scoreVal <= 89) ? 'score_circle2' : scoreVal > 89 ? 'score_circle1' : '' " :style="{left: scoreVal - 2 + '%'}"></div>
							</div>
						</div>
						<div class="score_text">健康分等級：100-90分以上視為「優等」； 89-60分視作「良好」； 59-1分視為「欠佳」。</div>
					</div>
				</div>
			</div>
			<div class="branch_wrap">
				<div class="branch_health branch_score">
					<div class="branch_content">
						<div class="branch_item_title">
							人群分類
						</div>
						<div class="branch_health_list">
							<div :class="res.base.health_type == 1 ? 'branch_health_item branch_health_1 branch_health_scale' :  'branch_health_item'">健康<span :class="res.base.health_type == 1 ? 'branch_health_item_ca' : ''">人群</span></div>
							<div :class="res.base.health_type == 2 ? 'branch_health_item branch_health_2 branch_health_scale' :  'branch_health_item'">亞健康<span :class="res.base.health_type == 2 ? 'branch_health_item_ca' : ''">人群</span></div>
							<div :class="res.base.health_type == 3 ? 'branch_health_item branch_health_3 branch_health_scale' :  'branch_health_item'">醫療需求<span :class="res.base.health_type == 3 ? 'branch_health_item_ca' : ''">人群</span></div>
							 <!-- branch_health_scale -->
						</div>
						<div class="branch_health_list">
							<div class="branch_health_item_text">{{res.base.health_type == 1 ? '您屬於' : ''}}</div>
							<div class="branch_health_item_text">{{res.base.health_type == 2 ? '您屬於' : ''}}</div>
							<div class="branch_health_item_text">{{res.base.health_type == 3 ? '您屬於' : ''}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="person_wrap">
				<van-grid class="person qjc-bg-fff" :column-num="3" :border="false">
					<van-grid-item>
						
						<dl class="text_right">
							<dt>體型評估</dt>
							<dd class="qjc-c-primary"><b>{{ res.m05.body_g[1] }}</b></dd>
						</dl>
						<dl class="text_right">
							<dt>未來{{ res.m10.person_yearsfee.length }}年醫療費用預測</dt>
							<dd class="qjc-c-primary"><b>{{ res.m10.person_yearsfee.slice(-1)[0] }}</b>港元</dd>
						</dl>
					</van-grid-item>
					<van-grid-item>
						<van-image
							width="1.84rem"
							height="5.5rem"
							:src="this.res.base.sex == 1?require('@/assets/images/man.png'):(this.res.base.sex == 2?require('@/assets/images/wuman.png'):null)"
						/>
					</van-grid-item>
					<van-grid-item>
						<dl class="text_left">
							<dt>百歲概率</dt>
							<dd class="qjc-c-primary"><b>{{ res.m01.lift_rates_100 ? res.m01.lift_rates_100 : 0 }}</b>%</dd>
						</dl>
						<dl class="text_left" v-if="res.m01.lift_rates_90">
							<dt>九十歲概率</dt>
							<dd class="qjc-c-primary"><b>{{ res.m01.lift_rates_90 ? res.m01.lift_rates_90 + '%' : '--' }}</b></dd>
						</dl>
						
					</van-grid-item>
				</van-grid>
			</div>
			
			<div class="list">
				<!-- 模块名列表 -->
				<van-sticky :z-index="1000" :offset-top="offsetTop">
					<ul class="list-title qjc-nowrap qjc-c-dark">
						<li v-if="modules.branch" @click="clickCheckModule('branch')" :class="{ active: active == 'branch' }">健康分</li>
						<li v-if="modules.total" @click="clickCheckModule('total')" :class="{ active: active == 'total' }">總體健康評估和生命預測</li>
						<li v-if="modules.bodily" @click="clickCheckModule('bodily')" :class="{ active: active == 'bodily' }">體型評估</li>
						<li v-if="modules.biom" @click="clickCheckModule('biom')" :class="{ active: active == 'biom' }">生化指标評估</li>
						<li v-if="modules.core" @click="clickCheckModule('core')" :class="{ active: active == 'core' }">潛在疾病風險評估</li>
						<li v-if="modules.health" @click="clickCheckModule('health')" :class="{ active: active == 'health' }">未來健康風險預測</li>
						<li v-if="modules.costs" @click="clickCheckModule('costs')" :class="{ active: active == 'costs' }">醫療費用預測</li>
						<li v-if="modules.illness" @click="clickCheckModule('illness')" :class="{ active: active == 'illness' }">未來疾病風險預測</li>
						<li v-if="modules.noSick" @click="clickCheckModule('noSick')" :class="{ active: active == 'noSick' }">不患病&增壽</li>
						<li v-if="modules.improve" @click="clickCheckModule('improve')" :class="{ active: active == 'improve' }">健康管理和改善方案</li>
					</ul>
				</van-sticky>
				
				<div @touchstart="touchStart($event)">
					<van-swipe
						@change="slide" 
						:show-indicators="false" 
						:loop="false"
						ref="swp"
					>
						<van-swipe-item v-if="modules.branch" @touchmove="checkMove($event)">
							<!-- 健康分 -->
							<div v-if="active == 'branch' || nextActive == 'branch'" class="branch qjc-bg-fff">
								<div class="branch_score">
									<div class="branch_content">
										<div class="branch_item_title">
											健康評分
											<i @click="scoreTip = !scoreTip" class="iconfont iconyiwen1 z_index9 qjc-relative">
												<div v-if="scoreTip" class="score_tip_box qjc-bg-fff qjc-absolute">
													<span class="score_tip_text">米因以大數據為基礎，綜合個人的當前健康狀況、潜在疾病風險和未來健康風險進行整體評分，給出百分制的「健康分」，以求盡可能對整體健康進行量化評估，既可用於表示個人健康狀況，也可作為個人健康管理的目標和評估標準。
													<br><span class="score_tip_span">对于个人而言，分數越高則相對越健康，健康分不適用於85歲以上的長者。</span></span>
													<div class="arrow"></div>
												</div>
											</i>
										</div>
										<div class="score_info" :style="{'--infoLeft': scoreVal > 3 ? scoreVal - 3 + '%' : scoreVal + 2 + '%' }">
											<div class="" v-if="base.age <= 85">
												您的健康評分
												<span :class="scoreVal <= 59 ? 'score_color3 score_val' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_val' : 'score_color1 score_val' ">{{scoreVal}}</span><span :class="scoreVal <= 59 ? 'score_color3 score_unit' : (scoreVal > 59 && scoreVal <= 89) ? 'score_color2 score_unit' : 'score_color1 score_unit' ">
												分({{scoreVal <= 59 ? '欠佳' : (scoreVal > 59 && scoreVal <= 89) ? '良好' : scoreVal > 89 ? '優等' : ''}})</span>
												<img v-if="scoreVal <= 59" class="xiao_icon" src="@/assets/images/health_3.png" />
												<img v-if="scoreVal > 59 && scoreVal <= 89" class="xiao_icon" src="@/assets/images/health_2.png" />
												<img v-if="scoreVal > 89" class="xiao_icon" src="@/assets/images/health_1.png" />
											</div>
											<div v-if="base.age > 85">健康分不適用於85歲以上的長者</div>
										</div>
										<div class="score_line">
											<div class="score_one_bg"></div>
											<div class="score_d1"></div>
											<div class="score_two_bg"></div>
											<div class="score_d2"></div>
											<div class="score_three_bg"></div>
											<div class="score_line_top">
												<div class="score_one" :style="{width: (scoreVal <=59 ? scoreVal + '%' : '59%')}"></div>
												<div v-if="scoreVal > 59" class="score_two" :style="{width: ((scoreVal > 59 && scoreVal <= 89) ? scoreVal - 59 + '%' : '30%')}"></div>
												<div v-if="scoreVal > 89"  class="score_three" :style="{width: (scoreVal > 89 ? scoreVal - 89 + '%' : '11%')}"></div>
												<div :class="scoreVal <=59 ? 'score_circle3' : (scoreVal > 59 && scoreVal <= 89) ? 'score_circle2' : scoreVal > 89 ? 'score_circle1' : '' " :style="{left: scoreVal - 2 + '%'}"></div>
											</div>
										</div>
										<div class="score_text">健康分等級：100-90分以上視為「優等」； 89-60分視作「良好」； 59-1分視為「欠佳」。</div>
									</div>
								</div>
								<div class="branch_course">
									<div class="branch_header">健康是人生的動態歷程</div>
									<div class="branch_content">
										<div class="branch_type_list">
											<div class="branch_type_item">健康</div>
											<div class="branch_type_jt"></div>
											<div class="branch_type_item">亞健康</div>
											<div class="branch_type_jt"></div>
											<div class="branch_type_item">患病者</div>
										</div>
										<div class="branch_type_item branch_100">過程是可逆的</div>
										<div class="branch_type_text">因不良生活習慣導致患病的慢病患者，在適當的醫療介入與生活習慣改善後，完全有可能逐漸康復、不再復發。</div>
									</div>
								</div>
								<div class="branch_course">
									<div class="branch_header">影響個人健康的因素</div>
									<div class="branch_content">
										<div class="branch_type_list">
											<div class="branch_type_c">基因遺傳</div>
											<div class="branch_type_c">生活環境</div>
											<div class="branch_type_c">生活習慣</div>
											<div class="branch_type_c">醫療支持</div>
											<div class="branch_type_c">特殊因素</div>
										</div>
									</div>
								</div>
								<div class="branch_course">
									<div class="branch_header">不同人群影響健康分的因素</div>
									<div class="branch_content">
										<div class="branch_yl">
											<span>醫療需求人群</span> 同一或不同疾病間都有輕重程度之別，在醫療科技與健康意識進步下，疾病的可控度與可愈度已進步很多，疾病未必會造成身心失衡或影響活力，囙此患病者也有可能在平穩控制病情和保持良好生活習慣的情况下，獲得比較高的健康分。
										</div>
										<div class="branch_yjk">
											<span>亞健康人群</span> 往往在不良生活習慣的影響下，身體已有許多潜在疾病但尚未表露，表面看似僅是忙碌勞累，實則是在透支生命與健康。 這種情況下往往只需要一兩次急症發作，便會全面引爆潜藏的健康危機，導致個人健康狀況的迅速惡化。 如果您認為自己較為健康，但卻獲得了不理想的健康分結果，還請您提高警惕，更多關愛自身。
										</div>
									</div>
								</div>
								<div class="branch_score">
									<div class="branch_content">
										<div class="branch_sangao_title">健康分與三高疾病的相關性</div>
										<div class="sangao_text">在人群大數據的維度中，健康分與各類疾病、重大傷病、門診次數、醫療費用、住院次數、住院天數甚至死亡率等均有高度相關性。</div>
										<div class="sel_cell">
											<select name="sangao" v-model="sangaoVal" class="select_style">
												<option value="1">高血壓</option>
												<option value="2">高血脂</option>
												<option value="3">高血糖</option>
											</select>
										</div>
										<div :class="sangaoVal == '1' ? 'dl_bg' : sangaoVal == '2' ? 'dl_bg2' : sangaoVal == '3' ? 'dl_bg3' : '' ">
											<div class="dl_bg_line" :style="{left: 100 - scoreVal + 10 + '%'}">
												<div class="dl_bg_tip">您的位置</div>
											</div>
										</div>
										<div class="dl_catg">
											<div class="dl_catg_wm">女性</div>
											<div class="dl_catg_m">男性</div>
										</div>
									</div>
								</div>
								<div class="branch_score">
									<div class="branch_content">
										<div class="branch_dl_title">健康分與其餘常見慢性疾病的相關性</div>
										<div class="sangao_text">在人群大數據的維度中，健康分與各類疾病、重大傷病、門診次數、醫療費用、住院次數、住院天數甚至死亡率等均有高度相關性。</div>
										<div class="sel_cell">
											<select name="dlVal" v-model="dlVal" class="select_style">
												<option value="1">癌症</option>
												<option value="2">肝炎、肝硬化</option>
												<option value="3">關節炎</option>
												<option value="4">甲狀腺异常</option>
												<option value="5">腦中風</option>
												<option value="6">貧血</option>
												<option value="7">氣喘</option>
												<option value="8">腎臟病、結石</option>
												<option value="9">失眠</option>
												<option value="10">疼痛</option>
												<option value="11">消化性潰瘍</option>
												<option value="12">心臟病</option>
												<option value="13">copd</option>
											</select>
										</div>
										<div :class="dlVal == '1' ? 'dl_bg4' : dlVal == '2' ? 'dl_bg5' : dlVal == '3' ? 'dl_bg6' : dlVal == '4' ? 'dl_bg7' : dlVal == '5' ? 'dl_bg8' 
										: dlVal == '6' ? 'dl_bg9' : dlVal == '7' ? 'dl_bg10' : dlVal == '8' ? 'dl_bg11' : dlVal == '9' ? 'dl_bg12' : dlVal == '10' ? 'dl_bg13' : dlVal == '11' ? 'dl_bg15' 
										: dlVal == '12' ? 'dl_bg15' : dlVal == '13' ? 'dl_bg16' : ''">
											<div class="dl_bg_line" :style="{left: 100 - scoreVal + 10 + '%'}">
												<div class="dl_bg_tip">您的位置</div>
											</div>
										</div>
										<div class="dl_catg">
											<div class="dl_catg_wm">女性</div>
											<div class="dl_catg_m">男性</div>
										</div>
									</div>
								</div>
								<div class="branch_score">
									<div class="branch_content">
										<div class="branch_sangao_title">健康分與醫療利用的相關性</div>
										<div class="sangao_text">在人群大數據的維度中，健康分與各類疾病、重大傷病、門診次數、醫療費用、住院次數、住院天數甚至死亡率等均有高度相關性。</div>
										<div class="sel_cell">
											<select name="medical" v-model="medicalVal" class="select_style">
												<option value="1">平均門診次數</option>
												<option value="2">平均醫療費用</option>
												<option value="3">平均住院次數</option>
												<option value="4">平均住院天數</option>
											</select>
										</div>
										<div :class="medicalVal == '1' ? 'medical_dl_bg' : medicalVal == '2' ? 'medical_dl_bg2' : medicalVal == '3' ? 'medical_dl_bg3' : medicalVal == '4' ? 'medical_dl_bg4' : '' ">
											<div class="medical_dl_bg_line" :style="{left: 100 - scoreVal + 3 + '%'}">
												<div class="dl_bg_tip">您的位置</div>
											</div>
										</div>
										<div class="dl_catg">
											<div class="dl_catg_wm">女性</div>
											<div class="dl_catg_m">男性</div>
										</div>
									</div>
								</div>
								<div class="branch_score">
									<div class="branch_content">
										<div class="branch_sangao_title">健康分與重大傷病的相關性</div>
										<div class="sangao_text">在人群大數據的維度中，健康分與各類疾病、重大傷病、門診次數、醫療費用、住院次數、住院天數甚至死亡率等均有高度相關性。</div>
										<div class="sel_cell">
											<select name="injuryVal" v-model="injuryVal" class="select_style">
												<option value="1">重大傷病率</option>
												<option value="2">死亡率</option>
											</select>
										</div>
										<div :class="injuryVal == '1' ? 'injury_dl_bg' : injuryVal == '2' ? 'injury_dl_bg2' : '' ">
											<div class="medical_dl_bg_line" :style="{left: 100 - scoreVal + 4 + '%'}">
												<div class="dl_bg_tip">您的位置</div>
											</div>
										</div>
										<div class="dl_catg">
											<div class="dl_catg_wm">女性</div>
											<div class="dl_catg_m">男性</div>
											<div class="dl_catg_h">合計</div>
										</div>
									</div>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.biom" @touchmove="checkMove($event)">
							<!-- 生化指标评估 -->
							<div v-if="active == 'biom' || nextActive == 'biom'" class="biom qjc-texta-l qjc-bg-fff">
								<div class="tip_p">「生化檢驗」是檢測血液中的各種離子、糖類、脂類、蛋白質以及各種酶、激素和機體的多種代謝產物的含量，作為個人健康的「參照物」來推測身體健康程度。</div>
								<div class="tip_p">臨床上生化檢驗的目的在於疾病的診斷、追跡治療效果、病情預後指標及疾病的篩檢。 健檢時生化檢驗的目的在於找出未被發現的疾病，以達早期發現； 以便早期治療，提早療效。</div>
								<div class="tip_p">生化檢查除臨床與健檢在處理「既病」的相關應用外，我們更是可以利用相關生化檢查數據與生活習性問卷，發現可能的「未病」與「欲病」，以阻斷疾病的發生。</div>
								<div class="tip_p">大部分生化檢驗結果正常與否，均會參攷其是否落在正常值範圍而判定。 若檢驗數據异常，表示某種疾病存在的機率較大； 反之檢驗數據正常，表示某種疾病存在的機率較低。</div>
								<div class="tip_p">有時候，單一的檢驗值雖在正常範圍，但受其他健康條件的影響，或許有很大的機率無法有效的反映該檢驗的功能，而表現失效，也就是說任一檢驗反應在疾病上，都有「假陽性」與「假陰性」的問題。 為避免單一檢驗的失效，我們利用臨床資料庫，綜合各項檢驗與其他條件，建立疾病預測模型，再以您的生化檢驗值的結果，估算個人未來各類慢性病、癌症、重大病症、失能或死亡發生的機率，這是一般健檢報告無法表現與提供的。</div>
								<div class="tip_p">生化檢驗結果反應了個人健康程度，但是健康程度又與年齡、性別有關，所以多數生化檢驗值正常範圍應隨年齡、性別不同而變化，囙此我們也提供同族群（People Like Me--與您相似的人群--與您同性別同年齡段的人群）的各項檢驗值的平均數，以找出您需維持或改善的項目與目標， 作為健康管理的參攷依據。 現代社會生活節奏快壓力大，不健康的生活方式愈發普遍，因而同族群平均值也會受到相應的影響，例如對於中老年人群，血糖、膽固醇等四高相關指標的同族群平均值有可能顯著偏高，甚至超出臨床標準範圍，這種情況反而更加說明定期檢查與健康管理的重要性。 我們希望您能結合「臨床標準」與「同族群平均值」兩個維度，更加全面、客觀的掌握自身的健康狀況。</div>
								<!-- <div class="tip_p">怀孕会造成血压略微升高，这是正常的现象。另外，孕产期女性需要更加严格的监控血糖变化，请多加注意。</div> -->
								<p>同族群（PLM，People Like Me）：與您相似（同性別同年齡段的人群）</p>
								<div class="costs_top bomi_btn_box">
									<div class="">生化指标評估</div>
									<div class="bomi_btn_cell">
										<div :class="bomiBtn == 1 ? 'bomi_v_btn bomi_btn_active' : 'bomi_v_btn'" @click="bomiBtn = 1">與臨床標準比較</div>
										<div :class="bomiBtn == 2 ? 'bomi_g_btn bomi_btn_active' : 'bomi_g_btn'" v-if="hasGroup" @click="bomiBtn = 2">與同族群比較</div>
									</div>
								</div>
								<div class="bomi_top_cell">
									<div class="bomi_title">血常規檢查</div>
									<div class="bomi_res">檢查結果</div>
									<div class="bomi_v bomi_lc" v-if="bomiBtn == 1">臨床標準範圍</div>
									<div class="bomi_v bomi_btn_v" v-if="bomiBtn == 1">結果</div>
									<div class="bomi_v bomi_lc" v-if="bomiBtn == 2">同族群平均值</div>
									<div class="bomi_group bomi_btn_v" v-if="bomiBtn == 2">結果</div>
								</div>
								<div class="bomi_item cbc_cell" v-for="(cbc,index) in biomData.CBC">
									<div class="bomi_item_title">{{biomData.CBC[index].item_d}}</div>
									<div class="bomi_item_res">{{biomData.CBC[index].value}}<span>{{ biomData.CBC[index].unit }}</span></div>
									<div class="bomi_item_res" v-if="bomiBtn == 1">{{biomData.CBC[index].range}}{{biomData.CBC[index].unit}}</div>
									<div v-if="bomiBtn == 1" :class="biomData.CBC[index].group_n == '異常'?'bomi_item_v c-high':'bomi_item_v'">{{biomData.CBC[index].group_n}}</div>
									<div class="bomi_item_res" v-if="bomiBtn == 2">{{biomData.CBC[index].p50}}{{biomData.CBC[index].unit}}</div>
									<div v-if="bomiBtn == 2" :class="biomData.CBC[index].group_p == '偏高' || biomData.CBC[index].group_p == '相對較好' || biomData.CBC[index].group_p == '相同' || biomData.CBC[index].group_p == '偏低'?'bomi_item_group c-high':(biomData.CBC[index].group_p == '適中' || biomData.CBC[index].group_p == '正常偏低' || biomData.CBC[index].group_p == '正常偏高'?'bomi_item_group qjc-c-primary':(biomData.CBC[index].group_p == '優良'?'bomi_item_group c-nice':''))">{{ biomData.CBC[index].group_p }}</div>
								</div>
								<div class="bomi_top_cell">
									<div class="bomi_title">血糖檢查</div>
								</div>
								<div class="bomi_item" v-for="(item, index) in biomData.bloodGlucose">
									<div class="bomi_item_title">{{biomData.bloodGlucose[index].item_d}}</div>
									<div class="bomi_item_res">{{biomData.bloodGlucose[index].value}}<span>{{ biomData.bloodGlucose[index].unit }}</span></div>
									<div class="bomi_item_res"  v-if="bomiBtn == 1">{{biomData.bloodGlucose[index].range}}{{biomData.bloodGlucose[index].unit}}</div>
									<div  v-if="bomiBtn == 1" :class="biomData.bloodGlucose[index].group_n == '異常'?'bomi_item_v c-high':'bomi_item_v'">{{biomData.bloodGlucose[index].group_n}}</div>
									<div class="bomi_item_res" v-if="bomiBtn == 2">{{biomData.bloodGlucose[index].p50}}{{biomData.bloodGlucose[index].unit}}</div>
									<div  v-if="bomiBtn == 2" :class="biomData.bloodGlucose[index].group_p == '偏高' || biomData.bloodGlucose[index].group_p == '相對較好' || biomData.bloodGlucose[index].group_p == '相同' || biomData.bloodGlucose[index].group_p == '偏低'?'bomi_item_group c-high':(biomData.bloodGlucose[index].group_p == '適中' || biomData.bloodGlucose[index].group_p == '正常偏低' || biomData.bloodGlucose[index].group_p == '正常偏高'?'bomi_item_group qjc-c-primary':(biomData.bloodGlucose[index].group_p == '優良'?'bomi_item_group c-nice':''))">{{ biomData.bloodGlucose[index].group_p }}</div>
								</div>
								<div class="bomi_top_cell">
									<div class="bomi_title">血脂肪檢查</div>
								</div>
								<div class="bomi_item" v-for="(item, index) in biomData.bloodLipids">
									<div class="bomi_item_title">{{biomData.bloodLipids[index].item_d}}</div>
									<div class="bomi_item_res">{{biomData.bloodLipids[index].value}}<span>{{ biomData.bloodLipids[index].unit }}</span></div>
									<div class="bomi_item_res" v-if="bomiBtn == 1">{{biomData.bloodLipids[index].range}}{{biomData.bloodLipids[index].unit}}</div>
									<div v-if="bomiBtn == 1" :class="biomData.bloodLipids[index].group_n == '異常'?'bomi_item_v c-high':'bomi_item_v'">{{biomData.bloodLipids[index].group_n}}</div>
									<div class="bomi_item_res" v-if="bomiBtn == 2">{{biomData.bloodLipids[index].p50}}{{biomData.bloodLipids[index].unit}}</div>
									<div v-if="bomiBtn == 2" :class="biomData.bloodLipids[index].group_p == '偏高' || biomData.bloodLipids[index].group_p == '相對較好' || biomData.bloodLipids[index].group_p == '相同' || biomData.bloodLipids[index].group_p == '偏低'?'bomi_item_group c-high':(biomData.bloodLipids[index].group_p == '適中' || biomData.bloodLipids[index].group_p == '正常偏低' || biomData.bloodLipids[index].group_p == '正常偏高'?'bomi_item_group qjc-c-primary':(biomData.bloodLipids[index].group_p == '優良'?'bomi_item_group c-nice':''))">{{ biomData.bloodLipids[index].group_p }}</div>
								</div>
								<div class="bomi_top_cell">
									<div class="bomi_title">肝膽功能檢查</div>
								</div>
								<div class="bomi_item" v-for="(item, index) in biomData.ALT">
									<div class="bomi_item_title">{{biomData.ALT[index].item_d}}</div>
									<div class="bomi_item_res">{{biomData.ALT[index].value}}<span>{{ biomData.ALT[index].unit }}</span></div>
									<div class="bomi_item_res" v-if="bomiBtn == 1">{{biomData.ALT[index].range}}{{biomData.ALT[index].unit}}</div>
									<div v-if="bomiBtn == 1" :class="biomData.ALT[index].group_n == '異常'?'bomi_item_v c-high':'bomi_item_v'">{{biomData.ALT[index].group_n}}</div>
									<div class="bomi_item_res" v-if="bomiBtn == 2">{{biomData.ALT[index].p50}} {{biomData.ALT[index].unit}}</div>
									<div v-if="bomiBtn == 2" :class="biomData.ALT[index].group_p == '偏高' || biomData.ALT[index].group_p == '相對較好' || biomData.ALT[index].group_p == '相同' || biomData.ALT[index].group_p == '偏低'?'bomi_item_group c-high':(biomData.ALT[index].group_p == '適中' || biomData.ALT[index].group_p == '正常偏低' || biomData.ALT[index].group_p == '正常偏高'?'bomi_item_group qjc-c-primary':(biomData.ALT[index].group_p == '優良'?'bomi_item_group c-nice':''))">{{ biomData.ALT[index].group_p }}</div>
								</div>
								<div class="bomi_top_cell">
									<div class="bomi_title">腎功能檢查</div>
								</div>
								<div class="bomi_item" v-for="(item, index) in biomData.renalFunc">
									<div class="bomi_item_title">{{biomData.renalFunc[index].item_d}}</div>
									<div class="bomi_item_res">{{biomData.renalFunc[index].value}}<span>{{ biomData.renalFunc[index].unit }}</span></div>
									<div class="bomi_item_res" v-if="bomiBtn == 1">{{biomData.renalFunc[index].range}}{{biomData.renalFunc[index].unit}}</div>
									<div v-if="bomiBtn == 1" :class="biomData.renalFunc[index].group_n == '異常'?'bomi_item_v c-high':'bomi_item_v'">{{biomData.renalFunc[index].group_n}}</div>
									<div class="bomi_item_res" v-if="bomiBtn == 2">{{biomData.renalFunc[index].p50}}{{biomData.renalFunc[index].unit}}</div>
									<div v-if="bomiBtn == 2" :class="biomData.renalFunc[index].group_p == '偏高' || biomData.renalFunc[index].group_p == '相對較好' || biomData.renalFunc[index].group_p == '相同' || biomData.renalFunc[index].group_p == '偏低'?'bomi_item_group c-high':(biomData.renalFunc[index].group_p == '適中' || biomData.renalFunc[index].group_p == '正常偏低' || biomData.renalFunc[index].group_p == '正常偏高'?'bomi_item_group qjc-c-primary':(biomData.renalFunc[index].group_p == '優良'?'bomi_item_group c-nice':''))">{{ biomData.renalFunc[index].group_p }}</div>
								</div>
								
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.core" @touchmove="checkMove($event)">
							<!-- 潜在疾病風險評估 -->
							<div v-if="active == 'core' || nextActive == 'core'" class="core qjc-texta-l qjc-bg-fff">
								<div v-if="res.m15">
									<!-- <h3 class="chart-t qjc-fts-28 qjc-c-dark">常见疾病风险倍率如下表：</h3> -->
									<div class="costs_top ">潜在疾病風險評估</div>
									<div class="tip_p">為預測健康人群「未病」與亞健康人群「欲病」的發生、及醫療需求人群「既病」病情的演變，團隊利用海量健康與臨床資料，透過大資料分析建立疾病預測模式，將個人生活習性問卷、體態評估、生化檢驗結果評估等健康影響因數等資料，評估與預測個人可能罹患各項疾病的風險， 並透過同族群People Like Me模式，得到個人罹病的風險倍率，若倍率高於1愈多，表示個人罹患該疾病可能風險愈高。</div>
									<div class="tip_p">罹病的風險倍率代表著健康管理與疾病管理的迫切性，透過有效且積極的管理，將能有效的降低風險倍率，以阻斷「未病/欲病」的發生，或防止「既病」的進一步惡化。</div>
									<div class="tip_p">囙此，建議當倍率大於1時，宜針對高風險疾病每半年追跡檢查評估一次； 當倍率大於2時，宜針對高風險疾病做進一步的檢查或每半年追跡檢查評估一次。</div>
									<div class="biom-chart" key="biom-chart">
										
									</div>
									<div class="biom-chart_bottom">建議您進一步檢查或每半年追跡檢查評估</div>
									<div class="biom-chart_bottom2">建議您每半年追跡檢查評估</div>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.health" @touchmove="checkMove($event)">	
							<!-- 未来健康风险预测 -->
							<div v-if="active == 'health' || nextActive == 'health'" class="health qjc-bg-fff">
								<!-- 健康者 -->
								<div v-if="res.base.health_type == 1" class="health1 qjc-texta-l">
									<p>量測綜合生理年齡RealAge，目的是在希望您能體會個人健康現況，可以更注重個人健康促進，讓您未來能表現的更為年輕有活力。 錯誤或不好的生活習慣，會讓人身體生理機能的年齡比實際歲數老化，並新增罹病幾率和死亡幾率。</p>
									<p>綜合生理年齡RealAge，是經研究分析海量的健康資料庫，依照個人的健康危險因數（如：生活習慣、運動飲食、….等）的多寡與影響程度，分別給予正負分來計算，換句話說：RealAge＝實際年齡±影響健康危險因數之加總值。</p>
									<p>透過綜合生理年齡RealAge的檢測，瞭解健康危險因數對自己身體生理的影響程度，未來能加强良好飲食、運動、生活習慣，確實做好健康管理、預防保健與養生，以延緩老化、降低罹病與失能幾率。</p>
									<div class="qjc-texta-c">
										<dl>
											<dd class="qjc-c-primary">
												<b>{{ res.base.age }}</b>歲
											</dd>
											<dt>您的實際年齡</dt>
										</dl>
										<dl>
											<dd class="qjc-c-primary">
												<b>{{ res.m02.realage }}</b>歲
											</dd>
											<dt>您的綜合生理年齡 Real Age</dt>
										</dl>
									</div>
									<p>綜合生理年齡是從醫學、生物學的角度來衡量的年齡，表示身體的成長、成熟或衰老的程度。 米因在千萬人口的醫療健康大資料庫基礎上，挖掘出綜合生理年齡所對應的關鍵指標，為您提供詳細、準確的大資料分析結果。</p>
								</div>
								<!-- 亚健康 -->
								<div class="three_list" v-if="res.base.health_type == 2">
									<div class="costs_top">四高风险倍率</div>
									<div class="tip_p_wrap">
										<div class="tip_p">心血管疾病是人类头号杀手，每年因心血管疾病死亡的人数占总死亡人数的30%以上。</div>
										<div class="tip_p">心血管疾病包括心脏病、中风和血管疾病等。中国的中风发病率是西方国家的4倍。</div>
										<div class="tip_p">由心血管疾病引发的死亡约80%发生在中低收入家庭，且多发生在人的壮年期。</div>
										<div class="tip_p">导致心血管疾病的主要因素就是“四高”疾病：高血压、糖尿病、高胆固醇(高血脂)和高尿酸(痛风)。</div>
										<div class="tip_p">“四高”疾病可能引发各种严重的并发症，除了心血管疾病以外，常见的有肾脏损害、眼底损害等，这些并发症都可能危及生命。</div>
										<div class="tip_p">为了身体健康和生命安全，应经常检测血压、血糖，尽量做到“早发现、早介入、早阻断”，在“四高”疾病实际发生以前降低相关风险，及时阻断或延缓相关疾病和并发症的发生。</div>
									</div>
									<ul>
										<li v-for="item in threeHighsClassify()">
											<h3 class="qjc-texta-l qjc-c-dark qjc-fts-32">{{item.devg }}</h3>
											<dl>
												<dd class="qjc-c-primary qjc-ftw-b">
													<span :style="{color: item.sick && item.sick == 1 ? '#FF3131' : ''}">{{item.sick && item.sick == 1 ? '根據您填寫的問卷內容，您已患病' : item.rate == 0.1 && item.tag ? item.tag + '倍' : item.rate + '倍'}}</span>
												</dd>
												<dt class="qjc-ftw-b">{{item.sick && item.sick == 1 ? '' : '患病風險是同類族群平均患病風險的'}}</dt>
											</dl>
											<p class="qjc-texta-l">
												<span class="qjc-c-dark">主要症狀：</span>{{ item.desc }}
											</p>
											<p class="qjc-texta-l"><span class="qjc-c-dark qjc-ftw-b">注：</span>同族群（PLM，People Like Me）：與您相似的人群--與您同性別同年齡段的人群。</p>
										</li>
									</ul>
								</div>
							</div>
						</van-swipe-item>
						
						<van-swipe-item v-if="modules.total" @touchmove="checkMove($event)">
							<!-- 总体健康评估和生命预测 -->
							<div v-if="active == 'total' || nextActive == 'total'" class="total qjc-bg-fff">
								<!-- <van-grid class="total-t qjc-texta-l" :center="false" :border="false" :column-num="3" gutter="0.16rem">
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>预期寿命</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.pre_lift }}</span>岁</b>
											<van-image
												width="0.4rem"
												height="0.25rem"
												:src="require('@/assets/images/yuqishouming.png')"
											/>
										</div>
									</van-grid-item>
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>百岁概率</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.lift_100 ? res.m01.lift_100 : 0 }}</span>%</b>
											<van-image
												width="0.36rem"
												height="0.36rem"
												:src="require('@/assets/images/baisuigailv.png')"
											/>
										</div>
									</van-grid-item>
									<van-grid-item>
										<p class="qjc-c-dark qjc-wid-100"><b>失能的平均概率</b></p>
										<div class="qjc-c-primary qjc-wid-100">
											<b><span>{{ res.m01.lost_ability }}</span>%</b>
											<van-image
												width="0.36rem"
												height="0.36rem"
												:src="require('@/assets/images/shinengdepingjungailv.png')"
											/>
										</div>
									</van-grid-item>
								</van-grid> -->
								<div class="total_title">生命曲線圖</div>
								<div class="total-chart" key="total-chart">
									
								</div>
								<div class="total_item" v-if="res.m01.lift_rates_90">
									<div class="total_item_cell">
										<div class="total_item_title">九十歲概率</div>
										<div class="total_item_val">{{ res.m01.lift_rates_90 ? res.m01.lift_rates_90 : '--' }}<span>%</span></div>
									</div>
									<div class="total_item_text">如果您能努力於健康促進，仍有相當的機會活超過90歲，因生病或臥床而失能的比例也會隨之降低。</div>
								</div>
								<div class="total_item">
									<div class="total_item_cell">
										<div class="total_item_title">百歲概率</div>
										<div class="total_item_val">{{ res.m01.lift_rates_100 ? res.m01.lift_rates_100 : 0 }}<span>%</span></div>
									</div>
									<div class="total_item_text">不生病或延緩病情，降低疾病對健康的影響，必須透過健康促進與健康管理，才能「防未病、斷欲病、緩既病」，進而有效的「延長健康的餘命」。</div>
								</div>
								<div class="total_item">
									<div class="total_item_cell">
										<div class="total_item_title">失能的平均概率</div>
										<div class="total_item_val">{{ res.m01.lost_ability }}<span>%</span></div>
									</div>
									<div class="total_item_text">現時科技與生活環境之下，人類的壽限約120歲；但人類平均壽命約在80歲左右，距離120歲的壽限還有很大的進步空間。現在醫療科技進步，人類普遍面臨的是「生命」延長了，但因為不健康而生病，將導致不便或臥床，影響生活與生命品質，造成痛苦苟延殘喘的時間也可能跟著延長。 我們將可能影響生活品質的時間當成「失能時間」。</div>
								</div>
								<!-- <div class="hint qjc-texta-l">
									<p><span class="qjc-c-primary">友情提示：</span>现时科技与生活环境之下，人类的寿限约120岁；但人类平均寿命约在80岁左右，距离120岁的寿限还有很大的进步空间。现在医疗科技进步，人类普遍面临的是“生命”延长了，但因为不健康而生病，将导致不便或卧床，影响生活与生命品质，造成痛苦苟延残喘的时间也可能跟着延长。我们将可能影响生活品质的时间当成“失能时间”。</p>
									<p>不生病或延缓病情，降低疾病对健康的影响，必须透过健康促进与健康管理，才能“防未病、断欲病、缓既病”，进而有效的“延长健康的余命”。</p>
								</div> -->
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.bodily" @touchmove="checkMove($event)">
							<!-- 体型评估 -->
							<div v-if="active == 'bodily' || nextActive == 'bodily'" class="bodily">
								<div class="res">
									<h4 class="qjc-c-dark qjc-texta-l qjc-ftw-b qjc-fts-28">與同類族群比，您的體脂率<span class="res-bfr qjc-c-primary">{{ ['1','4','7'].indexOf(res.m05.body_g[0])!=-1?'偏低':(['2','5','8'].indexOf(res.m05.body_g[0])!=-1?'適中':(['3','6','9'].indexOf(res.m05.body_g[0])!=-1?'偏高':'未知')) }}</span>，BMI<span class="qjc-c-primary">{{ res.m05.bmi_g[1] }}</span>，在同类族群中属于<span class="qjc-c-primary">{{ res.m05.body_g[1] }}</span></h4>
									<table class="qjc-wid-100 qjc-bg-fff" border="0" cellpadding="0" cellspacing="0">
										<thead>
											<th>
												<span class="BFR-t">體脂率(%)</span>
												<span class="BMI-t">BMI</span>
											</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='偏低' }">偏低</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='適中' }">適中</th>
											<th :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.bmi_g[1]=='偏高' }">偏高</th>
										</thead>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['1','4','7'].indexOf(res.m05.body_g[0])!=-1 }">偏低</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='1'?require('@/assets/images/xiaoshou--blue.png'):require('@/assets/images/xiaoshou.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='1' }" class="img-hint">消瘦</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='4'?require('@/assets/images/jingshi--blue.png'):require('@/assets/images/jingshi.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='4' }" class="img-hint">精實</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='7'?require('@/assets/images/jianmei--blue.png'):require('@/assets/images/jianmei.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='7' }" class="img-hint">健美</span>
											</td>
										</tr>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['2','5','8'].indexOf(res.m05.body_g[0])!=-1 }">適中</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='2'?require('@/assets/images/miaotiao--blue.png'):require('@/assets/images/miaotiao.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='2' }" class="img-hint">苗條</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='5'?require('@/assets/images/shizhong--blue.png'):require('@/assets/images/shizhong.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='5' }" class="img-hint">適中</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='8'?require('@/assets/images/zhuangshuo--blue.png'):require('@/assets/images/zhuangshuo.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='8' }" class="img-hint">壯碩</span>
											</td>
										</tr>
										<tr>
											<td :class="{ 'qjc-c-primary qjc-ftw-b': ['3','6','9'].indexOf(res.m05.body_g[0])!=-1 }">偏高</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='3'?require('@/assets/images/yinxingfeipang--blue.png'):require('@/assets/images/yinxingfeipang.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='3' }" class="img-hint">隱性肥胖</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='6'?require('@/assets/images/yinxingfeipang2--blue.png'):require('@/assets/images/yinxingfeipang2.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='6' }" class="img-hint">隱性肥胖</span>
											</td>
											<td>
												<van-image
													width="0.5rem"
													height="1.06rem"
													:src="res.m05.body_g[0]=='9'?require('@/assets/images/feipang--blue.png'):require('@/assets/images/feipang.png')"
												/>
												<span :class="{ 'qjc-c-primary qjc-ftw-b': res.m05.body_g[0]=='9' }" class="img-hint">肥胖</span>
											</td>
										</tr>
									</table>
									<h4 class="qjc-c-dark qjc-texta-l qjc-ftw-b qjc-fts-28">{{res.m05.body_g[2]}}</h4>
								</div>
								<div class="targets qjc-texta-l qjc-ftw-b">
									<h4 class="qjc-c-dark qjc-fts-28">您的各項指标</h4>
									<div class="body_item">
										<div class="body_item_title">BMI</div>
										<div class="body_item_val">您的BMI：<span>{{res.base && res.base.bmi}}</span></div>
										<div class="body_echat">
											<div class="body_echat_title">與臨床標準比</div>
											<div class="line ">
												<div class="body_echat_val" :style="{'left': bmiLeft > 62 ? 62 + '%' : bmiLeft - 12 + '%'}">{{res.base && res.base.bmi}}<span :class="bmi_n[1] == '正常' ? 'body_val_zhong' : 'body_val_gao'">{{bmi_n[1]}}</span></div>
												<div class="biaoqian_box" :style="{'left': bmiLeft - 3 + '%'}">
													<img class="biaoqian" v-if="base.bmi < bmiVal[0]" src="@/assets/images/biaoqian2.png" alt="">
													<img class="biaoqian" v-if="base.bmi >= bmiVal[0] && base.bmi <= bmiVal[1]" src="@/assets/images/biaoqian3.png" alt="">
													<img class="biaoqian" v-if="base.bmi > bmiVal[1]" src="@/assets/images/biaoqian.png" alt="">
												</div>
												<div class="line_item line_di"><div class="body_val_text">{{bmiVal[0]}}</div></div>
												<div class="line_item line_zhong"><div class="body_val_text">{{bmiVal[1]}}</div></div>
												<div class="line_item line_gao"></div>
											</div>
										</div>
										<div class="body_item_group">與您同年齡、同性別的人，他們的BMI平均值為：<span>{{bmi_g[2]}}</span></div>
										<div class="body_item_group_db ">和他們相比，您的BMI：<span :class="bmi_g[1] == '適中' ? 'group_zhong' : 'group_gao'">{{bmi_g[1]}}</span></div>
									</div>
									<div class="body_item whr_wrap" >
										<div class="body_item_title">腰臀比WHR</div>
										<div class="body_item_val">您的WHR：<span>{{res.base && res.base.whr}}</span></div>
										<div class="body_echat">
											<div class="body_echat_title">與臨床標準比</div>
											<div class="line">
												<div class="body_echat_val" :style="{'left': whrLeft > 62 ? 62 + '%' : whrLeft - 12 + '%'}">{{res.base && res.base.whr}} <span :class="whr_n[1] == '正常' ? 'body_val_zhong' : 'body_val_gao'">{{whr_n[1]}}</span></div>
												<div class="biaoqian_box" :style="{'left': whrLeft - 3 + '%'}">
													<img class="biaoqian" v-if="base.whr > whrVal[1]" src="@/assets/images/biaoqian.png" alt="">
													<img class="biaoqian" v-if="base.whr <= whrVal[1]" src="@/assets/images/biaoqian3.png" alt="">
													<!-- <img class="biaoqian" src="@/assets/images/biaoqian3.png" alt=""> -->
												</div>
												<div class="line_item line_zhong"><div class="body_val_text">{{whrVal[1]}}</div></div>
												<div class="line_item line_gao"></div>
											</div>
										</div>
										<div class="body_item_group">與您同年齡、同性別的人，他們的WHR平均值為：<span >{{whr_g[2]}}</span></div>
										<div class="body_item_group_db ">和他們相比，您的WHR：<span :class="whr_g[1] == '適中' ? 'group_zhong' : 'group_gao'">{{whr_g[1]}}</span></div>
									</div>
									<div class="body_item">
										<div class="body_item_title">體脂率BFR</div>
										<div class="body_item_val">您的BFR：<span>{{res.base && res.base.fat}}%</span></div>
										<div class="body_echat">
											<div class="body_echat_title">與臨床標準比</div>
											<div class="line">
												<div class="body_echat_val" :style="{'left': fatLeft > 52 ? 52 + '%' : fatLeft - 20 + '%'}">{{res.base && res.base.fat}}% <span :class="fat_n[1] == '正常' ? 'body_val_zhong' : 'body_val_gao'">{{fat_n[1]}}</span></div>
												<div class="biaoqian_box" :style="{'left': fatLeft - 3 + '%'}">
													<img class="biaoqian" v-if="base.fat < fatMinVal" src="@/assets/images/biaoqian2.png" alt="">
													<img class="biaoqian" v-if="base.fat >= fatMinVal && base.fat <= fatMaxVal" src="@/assets/images/biaoqian3.png" alt="">
													<img class="biaoqian" v-if="base.fat > fatMaxVal" src="@/assets/images/biaoqian.png" alt="">
												</div>
												<div class="line_item line_di"><div class="body_val_text">{{fatVal[0]}}</div></div>
												<div class="line_item line_zhong"><div class="body_val_text">{{fatVal[1]}}</div></div>
												<div class="line_item line_gao"></div>
											</div>
										</div>
										<div class="body_item_group">與您同年齡、同性別的人，他們的BFR平均值為：<span>{{fat_g[2]}}</span></div>
										<div class="body_item_group_db ">和他們相比，您的WHR：<span :class="fat_g[1] == '適中' ? 'group_zhong' : 'group_gao'">{{fat_g[1]}}</span></div>
									</div>
									
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.costs" @touchmove="checkMove($event)">
							<!-- 医疗费用预测 -->
							<div v-if="active == 'costs' || nextActive == 'costs'" class="costs qjc-bg-fff">
								<div class="costs_top">醫療費用預測</div>
								<div class="">
									<div class="tip_p">健康問題除會造成生命損失外，也會形成個人與家庭的經濟壓力。 利用疾病現況評估與預測，可知個人在未來可能發生的慢性疾病、主要急重症或癌症，團隊可預估測未來數年醫療使用（如門診、住院、手術、用藥等）的頻率與相關費用趨勢； 從趨勢圖中，可體會健康促進、健康管理與疾病管理的重要性，持續不斷健康促進，除可以改善生活（命）的品質，也能減輕或消除不必要的醫療費用負擔。</div>
									<div class="tip_p">從醫療費用的變化趨勢與相關的健康評估預測，或可供做個人保險、投資理財等生涯規劃之參攷。</div>
									<div class="tip_p">設算管道是以大陸主要城市（上海、深圳等地）及香港、臺灣公開的統計資料，包含GDP（國民生產毛額）、NHE（國民醫療保健支出）、SHA（醫療衛生總開支帳目系統），比較歷年數據的差异，定義各都市的相對權重，再依相關費用資料為基準，進行設算。</div>
									<div class="tip_p">社會、經濟環境快速改變，個人消費水準差距懸殊，此醫療費用預測結果僅取代表最大部分人的醫療健康“基礎”支出數據，僅供參考。</div>
								</div>
								<ul>
									<li class="qjc-clearfix">
										<dl class="qjc-fl qjc-texta-l">
											<dd class="qjc-fts-28">
												<b>{{ res.m10.person_yearsfee.slice(-1)[0] }}</b>
												港元
											</dd>
											<dt>未來{{ res.m10.person_yearsfee.length }}年主要醫療費用</dt>
										</dl>
										<van-image
											width="3.43rem"
											height="1.35rem"
											class="qjc-fr"
											:src="require('@/assets/images/costs_all_img.png')"
										/>
									</li>
									<li class="qjc-clearfix" v-show="res.m10.person_allfee">
										<dl class="qjc-fl qjc-texta-l">
											<dd class="qjc-fts-28">
												<b>{{ res.m10.person_allfee }}</b>
												港元
											</dd>
											<dt>終生累計醫療費用預估</dt>
										</dl>
										<van-image
											width="3.43rem"
											height="1.35rem"
											class="qjc-fr"
											:src="require('@/assets/images/costs_p_img.png')"
										/>
									</li>
								</ul>
								<div class="costs-chart_title">未來十年主要醫療費用演化趨勢</div>
								<div class="costs-chart" key="costChart">
									
								</div>
								<p class="qjc-texta-l"><span class="qjc-c-dark qjc-ftw-b">注：</span>同族群（PLM，People Like Me）：與您相似的人群--與您同性別同年齡段的人群。</p>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.illness" @touchmove="checkMove($event)">
							<!-- 未来疾病风险预测 -->
							<div v-if="active == 'illness' || nextActive == 'illness'" class="illness qjc-texta-l qjc-bg-fff">
								<div>
									<h3 class="qjc-c-dark">{{ res.base.health_type == 1?'系統疾病風險':(res.base.health_type == 2?'慢性病風險':(res.base.health_type == 3?'急重症風險':'')) }}</h3>
									<div class="tip_p_wrap" v-if="res.base.health_type == 1">
										<div class="tip_p">生理系統失衡將導致發展為長期慢性病，不可逆的長期慢性病不但影響生活品質，也可使逐步並發成急重症、癌症的風險增加。</div>
										<div class="tip_p">從「生理系統疾病」演化情形可以預測個人未來數年罹患風險較高的慢性病，曲線的上升表示未來風險逐年提高。主要系統有：消化、呼吸、骨骼、肌肉、感覺器官、內分泌代謝和免疫系統。</div>
										<div class="tip_p">預測未來罹病風險高，若能適時適量的給予健康促進，並追蹤檢查評估，將能降低個人的患病風險，更能有助於未病先防。健康人群儘早做健康管理與促進的效益是最高的！因為各種健康風險尚在醞釀中，尚未發生器質性變化，這個階段的健康風險大多與生活、飲食與運動習慣息息相關，只要及早發現發現問題所在，大部分風險是可以逆轉的。</div>
									</div>
									<div class="tip_p_wrap" v-if="res.base.health_type == 2">
										<div class="tip_p">亞健康人群生活習慣欠佳，雖然目前看起來好好的，但長期發展下來，可能成為慢性病高危險群，甚至提高罹患急重症：如癌症、心梗與中風等的風險。</div>
										<div class="tip_p">從疾病現況評估可以進一步預測：個人未來數年可能罹患慢性病或急重症(稱為「欲病」)的風險與演化趨勢，估測出其未來罹病風險倍率，對高風險倍率之急重症，密切掌握其「欲病」與其未來該欲病的演變關聯。</div>
										<div class="tip_p">除預測未來罹病風險外高，若能適時適量的給予健康促進，改善核心指標，並追蹤檢查評估，將能降低個人的罹病風險，更能有助於斬斷「欲病」的發生。</div>
									</div>
									<div class="tip_p_wrap" v-if="res.base.health_type == 3">
										<div class="tip_p">對醫療需求人群，即「既病者」而言，在病情急速下降或惡化，或將增加罹患急重症及失能的風險，甚至威脅生命。</div>
										<div class="tip_p">從疾病現況評估可以預測「既病者」個人未來數年可能罹患急重症的風險與演化趨勢，並與同族群PeopleLikeMe模式搭配，估測其罹病風險倍率，對高風險倍率之急重症，宜關切「既病」與該急重症的演變關聯。及早的疾病風險預警，可以幫助大眾及時發現問題、定位問題，結合專業醫療機構的幫助以及對自身狀況的了解，做到心中有數，有備無患，並及早做好疾病管理，及早防患、及早發現、及早治療。</div>
									</div>
									
									<ul>
										<li v-for="(item, index) in res.m07.dis_risk">
											<div class="illness-t">
												<b class="name qjc-c-dark">{{ index+1 }}.{{ item.devg }}</b>
												<span @click="chartOn('risk',index, item, $event)" class="qjc-fr" v-if="item.person_rates.length > 1">
													查看詳細圖表
													<van-icon
														name="arrow-down"
													/>
												</span>
											</div>
											<div class="chart qjc-hide" :key="item.devg">
												
											</div>
											<div class="prob qjc-texta-c qjc-clearfix">
												<dl class="qjc-fl" :class="res.base.health_type == 1 || !item.compare_rates || item.compare_rates.length < 1 ?  'qjc-wid-100' : 'qjc-wid-50'">
													<dd class="qjc-c-primary">
														<b>{{ arrMax(item.person_rates) }}</b>%
													</dd>
													<dt>未來{{ item.person_rates.length }}年最高患病概率</dt>
												</dl>
												<dl v-if="res.base.health_type != 1 && item.compare_rates && item.compare_rates.length > 0" class="qjc-fl qjc-wid-50">
													<dd class="qjc-c-primary">
														<div v-if="item.person_rates[0]>0 && item.compare_rates[0]>0">
															<b>{{ (item.person_rates[0]/item.compare_rates[0]).toFixed(2) }}</b>倍
														</div>
														<div v-else>
															<b>不適用</b>
														</div>
													</dd>
													<dt>是同族群平均概率的</dt>
												</dl>
											</div>
											<div class="symptom">
												<span class="qjc-c-dark qjc-ftw-b">主要症狀：</span>
												{{ item.desc }}
											</div>
											<p class="qjc-texta-l"><span class="qjc-c-dark">注：</span>同族群（PLM，People Like Me）：與您相似的人群--與您同性別同年齡段的人群。</p>
										</li>
									</ul>
								</div>
								<div v-if="res.base.health_type == 3 && res.m07.cancer_risk && res.m07.cancer_risk.length>0">
									<h3 class="qjc-c-dark">癌症風險</h3>
									<div class="tip_p_wrap">
										<div class="tip_p">根據臨床數據研究，癌症常是一種或多種「生活習慣病」日積月累形成「癌症病根」，在個人免疫力或健康不佳時，病根就會適時浮現為「癌症確診」。因此癌症的防治，除須有良好的健康管理，增強體力與免疫力；慢性病患更需體會各既有慢性病對癌症可能觸發的影響，了解各項慢性病的發展，並確實做好慢性病的管理，以有效降低癌症發生的風險。</div>
										<div class="tip_p">罹患癌症不僅影響生活品質，甚至威脅生命。但癌症不可怕，依據科學的方法早期發現「癌症病根」，早期阻斷；或早期發現，早期治療，常可獲得控制，延長壽命。</div>
									</div>
									<ul>
										<li v-for="(item, index) in res.m07.cancer_risk">
											<div class="illness-t">
												<b class="name qjc-c-dark">{{ index+1 }}.{{ item.devg }}</b>
												<span @click="chartOn('cancer', index, item, $event)" class="qjc-fr">
													查看詳細圖表
													<van-icon
														name="arrow-down"
													/>
												</span>
											</div>
											<div class="chart qjc-hide" :key="item.devg">
												
											</div>
											<div class="prob qjc-texta-c qjc-clearfix">
												<dl class="qjc-fl qjc-wid-100">
													<dd class="qjc-c-primary">
														<b>{{ arrMax(item.rates) }}</b>倍
													</dd>
													<dt>未來二十年最高患病倍率</dt>
												</dl>
											</div>
											<div class="symptom">
												<span class="qjc-c-dark qjc-ftw-b">主要症狀：</span>
												{{ item.desc }}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.noSick" @touchmove="checkMove($event)">
							<!-- 不患病&增寿 -->
							<div v-if="active == 'noSick' || nextActive == 'noSick'" class="noSick">
								<div>
									<div class="">
										<div class="tip_p">許多慢性病是生活習慣不良所引起，故又稱為生活習慣病或文明病。疾病會影響壽命長短，若能降低罹患風險，壽命就會相應延長。 決定長壽的因素很多，長壽不是一早註定的，而是通過自己的長期堅持與努力獲得的。只要控制好健康風險，任何人都可以比較健康長壽。</div>
										<div class="tip_p">在此部分給出的，是基於相關疾病，針對您的性別、年齡、基本健康風險等基本情況，計算各年齡的患病概率，再找出其中患病概率最高的歲數。 例如：{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].devg}}，最高風險概率{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].rr_desc}}%，疾病影響最高年歲{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].maxage5}}歲，不患病可增壽年數{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].adagen_adj}}年——指的是對於該用戶來說，其{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].devg}}的患病概率會在{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].maxage5}}歲之前持續上升，在{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].maxage5}}歲時達到最高的{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].rr_desc}}%，在{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].maxage5}}歲之後則轉為下降；若終生不得{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].devg}}，則預期可增壽{{res.m08.filter((a)=>a.adagen_adj!=-999)[0].adagen_adj}}年。</div>
									</div>
									<h3 class="qjc-texta-l qjc-fts-36 qjc-c-dark">原發性疾病</h3>
									
									<ul>
										<li v-for="item in res.m08.filter((a)=>a.adagen_adj!=-999)">
											<h4 class="qjc-texta-l qjc-fts-32 qjc-c-dark">{{ item.devg }}</h4>
											<div class="qjc-clearfix">
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.rr_desc }}</b>%
													</dd>
													<dt>最高風險概率</dt>
												</dl>
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.maxage5 }}</b>岁
													</dd>
													<dt>疾病影響最高年歲</dt>
												</dl>
											</div>
											<p>不患病可增壽年數：<span class="qjc-c-primary"><b class="qjc-fts-32">{{ item.adagen_adj }}</b>年</span></p>
										</li>
									</ul>
								</div>
								<div>
									<h3 class="qjc-texta-l qjc-fts-36 qjc-c-dark">併發性疾病</h3>
									<ul>
										<li v-for="item in res.m08.filter((a)=>a.adagen_adj==-999)">
											<h4 class="qjc-texta-l qjc-fts-32 qjc-c-dark">{{ item.devg }}</h4>
											<div class="qjc-clearfix">
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.rr_desc }}</b>%
													</dd>
													<dt>最高風險概率</dt>
												</dl>
												<dl class="qjc-wid-50 qjc-fl">
													<dd class="qjc-ftw-b qjc-c-dark">
														<b class="qjc-fts-32">{{ item.maxage5 }}</b>岁
													</dd>
													<dt>疾病影響最高年歲</dt>
												</dl>
											</div>
											<!-- <p>不患病可增壽年數：<span class="qjc-c-primary">--</span></p> -->
										</li>
									</ul>
								</div>
							</div>
						</van-swipe-item>
						<van-swipe-item v-if="modules.improve" @touchmove="checkMove($event)">
							<!-- 健康管理和改善方案 -->
							<div v-if="active == 'improve' || nextActive == 'improve'" class="improve qjc-texta-l qjc-bg-fff">
								<div v-if="res.m12 && res.m14" class="improve-body">
									<div class="target">
										<h3 class="qjc-fts-36 qjc-c-dark">體型改善目標</h3>
										<h4 class="qjc-c-dark qjc-fts-32">建議改善方案</h4>
										<div class="program-res">
											<dl>
												<dt>計畫時長</dt>
												<dd class="qjc-texta-l">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m12.weeks.slice(-1)[0] }}</b>
													周
												</dd>
											</dl>
											<dl>
												<dt>平均每日飲食熱量</dt>
												<dd class="qjc-texta-c">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.bmr }}</b>
													大卡
												</dd>
											</dl>
											<dl>
												<dt>建議熱量改善目標</dt>
												<dd class="qjc-texta-c">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.bmr_df }}大卡/日</b>
												</dd>
											</dl>
											<dl>
												<dt>建議運動次數</dt>
												<dd class="qjc-texta-r">
													<b class="qjc-fts-32 qjc-c-dark">{{ res.m14.s1+res.m14.s2+res.m14.s3 }}</b>
													次/周
												</dd>
											</dl>
										</div>
										<div class="weight_chart_title">體重改善目標</div>
										<div class="weight_chart"></div>
										<div class="weight_chart_title">腰臀比改善目標</div>
										<div class="whr_chart"></div>
										
									</div>
									<div class="program">
										
										<div class="new_bmi">
											<!--BMI 体型 -->
											<div class="new_bmi_cell">您的BMI是 <span class="new_bmi_val">{{res.m14.bmi}}</span>，屬於 <span class="new_bmi_b">{{res.m14.bmi_stat}}</span>型；</div>
											<!-- 理想体重 -->
											<div class="new_bmi_cell">您的理想體重為 <span class="new_bmi_lx bmi_lx1" >{{res.m14.st_wd}}</span> ~ <span class="new_bmi_lx bmi_lx2">{{res.m14.st_wu}}</span>公斤；</div>
											<!--运动和饮食改善 -->
											<div class="new_bmi_p">體重為影響健康的最重要指標之一，過胖或過瘦有可能對健康造成危害，導致疾病的發生，應注意體重的維持及改善，通過運動及飲食來維持及改善體重最為理想，依據您的BMI與理想體重，建議您應<span class="new_bmi_p_v">{{res.m14.food_supp}}</span>，及<span class="new_bmi_p_v2">{{res.m14.sport_supp}}</span>;</div>
											
										</div>
										
										<div class="eat">
											<h5 class="qjc-fts-28 qjc-c-dark">飲食部分建議</h5>
											<p>說明：1、一份約一個成人拳頭或手掌大。2、一杯约500cc</p>
											<ul>
												<li>
													<div class="" v-if="res.m14.vege == 1">
														<p>飲食部分建議先<span class="improve_c0">{{res.m14.c0}}</span>肉類<b>{{res.m14.c1 == 0 ? '不變' : res.m14.c1 + '卡'}}</b>，
															再<span class="improve_c0">{{res.m14.c0}}</span>主食<b class="staple_ka">{{res.m14.c2 == 0 ? '不變' : res.m14.c2 + '卡'}}</b>,
															豆類<b class="beans_ka">{{res.m14.c3 == 0 ? '維持' : res.m14.c3 + '卡'}}</b>，
															水果<b class="fruits_ka">{{res.m14.c4 == 0 ? '維持' : res.m14.c4 + '卡'}}</b>，
															乳製品<b class="cream_ka">{{res.m14.c5 == 0 ? '維持' : res.m14.c5 + '卡'}}</b>。
														</p>
													</div>
													<div class="" v-if="res.m14.vege == 2">
														<p>飲食部分建議先<span class="improve_c0">{{res.m14.c0}}</span>主食<b>{{res.m14.c2 == 0 ? '不變' : res.m14.c2 + '卡'}}</b>，
															再<span class="improve_c0">{{res.m14.c0}}</span>豆類<b class="staple_ka">{{res.m14.c3 == 0 ? '維持' : res.m14.c3 + '卡'}}</b>,
															水果<b class="beans_ka">{{res.m14.c4 == 0 ? '維持' : res.m14.c4 + '卡'}}</}}</b>，
															乳製品<b class="fruits_ka">{{res.m14.c5 == 0 ? '維持' : res.m14.c5 + '卡'}}</b>。
														</p>
													</div>
												</li>
												<li>
													<div class="m14_t">
														<van-image
															width="0.3rem"
															height="0.28rem"
															:src="require('@/assets/images/zhushilei.png')"
														/>
														主食類（擇一）
													</div>
													<div class="m14_v">
														<p>以米飯為主食者，每天請<span class="improve_c0" v-if="res.m14.c21 != 0">{{res.m14.c0}}</span><b>{{res.m14.c21 == 0 ? '維持' : res.m14.c21 + '碗'}}</b></p>
														<p>以麵條為主食者，每天請<span class="improve_c0" v-if="res.m14.c22 != 0">{{res.m14.c0}}</span><b>{{res.m14.c22 == 0 ? '維持' : res.m14.c22 + '碗'}}</b></p>
														<p>以雜糧為主食者，每天請<span class="improve_c0" v-if="res.m14.c23 != 0">{{res.m14.c0}}</span><b>{{res.m14.c23 == 0 ? '維持' : res.m14.c23 + '碗'}}</b></p>
														<p>以麵包為主食者，每天請<span class="improve_c0" v-if="res.m14.c24 != 0">{{res.m14.c0}}</span><b>{{res.m14.c24 == 0 ? '維持' : res.m14.c24 + '克'}}</b></p>
													</div>
												</li>
												<li v-if="res.m14.vege == 1">
													<div class="m14_t">
														<van-image
															width="0.31rem"
															height="0.28rem"
															:src="require('@/assets/images/roulei.png')"
														/>
														肉類<br>（擇一）
													</div>
													<div class="m14_v">
														<p>以牛肉、猪肉、羊肉家畜等，每天請<span class="improve_c0" v-if="res.m14.c11 != 0">{{res.m14.c0}}</span><b>{{res.m14.c11 == 0 ? '維持' : res.m14.c11 + '份'}}</b></p>
														<p>以魚、蝦、蟹，海鮮等，每天請<span class="improve_c0" v-if="res.m14.c12 != 0">{{res.m14.c0}}</span><b>{{res.m14.c12 == 0 ? '維持' : res.m14.c12 + '份'}}</b></p>
														<p>以雞肉、鴨肉，家禽等，每天請<span class="improve_c0" v-if="res.m14.c12 != 0">{{res.m14.c0}}</span><b>{{res.m14.c12 == 0 ? '維持' : res.m14.c12 + '份'}}</b></p>
													</div>
												</li>
												<li>
													<div class="m14_t">
														<van-image
															width="0.31rem"
															height="0.28rem"
															:src="require('@/assets/images/shucai.png')"
														/>
														蔬菜類
													</div>
													<div class="m14_v">指的是葉菜（如菠菜、洋白菜）、花菜（如花椰菜、金針花）、海菜（如紫菜、海帶）、菇類（如香菇、洋菇）等，每日宜多變化並以當季新鮮為主。 每天請<span class="improve_c0">增加</span><b>{{res.m14.c99}}份</b></div>
													<!-- <b class="qjc-c-dark m14_l">
														<span>{{ res.m14.c0=='维持'?'+0.5':'+'+accMul(res.m14.c21,1.5) }}</span>份
													</b> -->
												</li>
												<li>
													<div class="m14_t">
														<van-image
															width="0.2rem"
															height="0.3rem"
															:src="require('@/assets/images/doulei.png')"
														/>
														豆類
													</div>
													<div class="m14_v">指的是提供豐富植物性蛋白質的黃豆、毛豆、黑豆、其他豆類及其製品，如豆腐、無糖豆漿、小方豆干等。 每天請<span class="improve_c0" v-if="res.m14.c31 != 0">{{res.m14.c0}}</span><b>{{res.m14.c31 == 0 ? '維持' : res.m14.c31 + '份'}}</b></div>
													
												</li>
												<li>
													<div class="m14_t">
														<van-image
															width="0.24rem"
															height="0.3rem"
															:src="require('@/assets/images/shuiguolei.png')"
														/>
														水果類
													</div>
													<div class="m14_v">不含蔬菜。 每天請<span class="improve_c0" v-if="res.m14.c41 != 0">{{res.m14.c0}}</span><b>{{res.m14.c41 == 0 ? '维持' : res.m14.c41 + '份'}}</b></div>
												</li>
												<li>
													<div class="m14_t">
														<van-image
															width="0.27rem"
															height="0.3rem"
															:src="require('@/assets/images/ruzhilei.png')"
														/>
														乳脂類
													</div>
													<div class="m14_v">指的是鮮乳、保久乳、優酪乳及乳酪等乳製品。 每天請<span class="improve_c0" v-if="res.m14.c51 != 0">{{res.m14.c0}}</span><b>{{res.m14.c51 == 0 ? '維持' : res.m14.c51 + '份'}}</b></div>
												</li>
											</ul>
										</div>
										<div class="sport" v-if="res.m14.s1">
											<h4 class="qjc-fts-28 qjc-c-dark">運動部分建議</h4>
											<p>運動項目依强度，分為低强度、中强度與高强度，各强度運動項目可參攷下列所述，建議根據個人作息時間與身心負擔，進行適當的彈性調整。</p>
											<table class="qjc-wid-100" border="0" cellpadding="0" cellspacing="0">
												<tr>
													<th></th>
													<th>低强度運動</th>
													<th>中强度運動</th>
													<th>高强度運動</th>
												</tr>
												<tr>
													<td>增加次數</td>
													<td><span>{{ res.m14.s1 }}</span>次/周</td>
													<td><span>{{ res.m14.s2 }}</span>次/周</td>
													<td><span>{{ res.m14.s3 }}</span>次/周</td>
												</tr>
												<tr>
													<td>時長</td>
													<td><span>30</span>分钟</td>
													<td><span>30</span>分钟</td>
													<td><span>30</span>分钟</td>
												</tr>
											</table>
											<div class="symptom">
												<p>低强度運動：如散步、做家事、逛街、遛狗等；</p>
												<p>中强度運動：如快步走、有氧運動、打太極拳、騎腳踏車、打高爾夫球、爬樓梯、跳舞、打桌球、騎馬等；</p>
												<p>高强度運動：如游泳、打網球、打籃球、跑步、仰臥起坐、滑雪、跳繩等。</p>
											</div>
										</div>
									</div>
								</div>
								<div class="improve-biom" v-if="res.m13">
									<h3 class="qjc-fts-36 qjc-c-dark">生化指标改善方案</h3>
									<p>生化指標會經常發生改變，代表著健康狀況和健康風險發生了變化。 定期使用大數據進行分析，及時發現問題，預防疾病，掌握健康。</p>
									<p>根據您的健康狀況，我們建議您在短期內對生化指標進行適當改善，改善目標如下：</p>
									<div class="improve-table">
										<table class="qjc-wid-100" border="0" cellpadding="0" cellspacing="0">
											<thead>
												<td></td>
												<td>主要檢查項目</td>
												<td>檢查結果</td>
												<td>健康風險</td>
												<td>短期改善目標</td>
											</thead>
											<tr v-for="(item, index) in improveBiomData.CBC" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.CBC.length" class="left-side">血液常規</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.bloodGlucose" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.bloodGlucose.length" class="left-side">血糖</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.bloodLipids" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.bloodLipids.length" class="left-side">血脂肪</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.ALT" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.ALT.length" class="left-side">肝膽功能</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
											<tr v-for="(item, index) in improveBiomData.renalFunc" :key="item.id">
												<td v-if="index==0" :rowspan="improveBiomData.renalFunc.length" class="left-side">腎功能</td>
												<td :class="{ 'qjc-ftw-b qjc-c-dark': item.score>2 }">{{ item.item_d }}</td>
												<td><b>{{ item.value }}</b> {{ item.unit }}</td>
												<td>
													<i v-for="i in item.score" :key="i" :class="{ 'iconxiaolian': item.score == 1,'iconbukubuxiaolian': item.score == 2,'iconkulian c-danger': item.score > 2 }" class="iconfont"></i>
												</td>
												<td>{{ item.score <= 2?item.target:item.target+item.unit }}</td>
											</tr>
										</table>
									</div>
									<div class="symptom">
										<i class="iconfont iconxiaolian"> ：良好</i>
										<i class="iconfont iconbukubuxiaolian"> ：一般</i>
										<i class="iconfont iconkulian c-danger"> ：需要改善</i>
									</div>
								</div>
							</div>
						</van-swipe-item>
					</van-swipe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Grid, GridItem, Image, Icon, Sticky, Toast, Swipe, SwipeItem, Circle } from 'vant'
	Vue.use(NavBar)
	   .use(Grid)
	   .use(GridItem)
	   .use(Image)
	   .use(Icon)
	   .use(Sticky)
	   .use(Toast)
	   .use(Swipe)
	   .use(SwipeItem)
	   .use(Circle);
	
	import { resultCommit } from '@/js/axios.js'
	import { accMul } from '@/js/utils.js'
	var echarts = require('echarts');
	
	export default{
		name: 'result',
		data (){
			return {
				id: '',//报告id
				c: null,//报告修改次数 只能修改一次
				active: 'branch',//当前展示模块(与class名对应) 默认第一个生化指标
				nextActive: '',//另一个展示模块(左右切换的另一个模块,避免出现空白)
				offsetTop: 0,//模块列表吸顶距离 默认为0
				scoreTip: false, //健康分 tip
				scoreTip1: false,
				scoreVal: 0,
				infoLeft: '4%',
				sangaoVal: '1',
				dlVal: '1',
				medicalVal: '1',
				injuryVal: '1',
				res: {
					base: {
						package:'',
						health_type: '',//所属人群
						height: '',//身高
						weight: '',//体重
						fat: '',//体脂率
						bust: '',//胸围
						waist: '',//腰围
						hip: '',//臀围
						sex: ''//男女 1男 2女
					},
					m01: {//总体健康评估和生命预测
						pre_lift: '',//预期寿命
						lift_100: '',//百岁概率
						pre_rem: '',//年龄区间
						rates_ages: [],//年龄
						heal_rates: [],//健康率
						lift_rates: [],//存活概率
						lost_heal_ratio: []//失能率
					},
					m05: {//体型评估
						body_g: [],//体型评估总结果
						bmi_g: [],//bmi
						whr_g: [],//whr
						fat_g: []//bfr
					},
					m06: {},//生化指标评估表格数据
					m07: {//未来疾病风险预测
						dis_risk: [],//疾病列表
						cancer_risk: []//癌症列表(医疗需求者可能会有)
					},
					m08: [],//不患病&增寿
					m10: {//医疗费用预测
						person_allfee: '',//终生医疗费用预估
						person_yearsfee: [],//未来十年医疗费用预估
						compare_yearsfee: []//未来十年同族群医疗费用预估
					},
					m12: {//健康管理和改善方案
						sugweight: [],//当前体重取[0] 目标体重取最后一个
						sugwhr: [],//当前腰臀比去[0] 目标腰臀比去最后一个
						weeks: []//计划时长
					},
					m14: {//健康管理和改善方案
						bmr: '',//平均每日饮食热量
						c0: '',//判断当前维持状态
						c21: '',//主食类
						c12: '',//肉类
						c31: '',//豆类
						c41: '',//水果类
						c51: ''//乳脂类
					},
					m15: []//生化指标评估：常见疾病风险倍率
				},//数据结构 和后台传输数据保持一致
				biomData: {
					CBC: [],//血液常规
					bloodGlucose: [],//血糖
					bloodLipids: [],//血脂肪
					ALT: [],//肝胆功能
					renalFunc: []//肾功能
				},//生化指标评估数据
				improveBiomData: {
					CBC: [],//血液常规
					bloodGlucose: [],//血糖
					bloodLipids: [],//血脂肪
					ALT: [],//肝胆功能
					renalFunc: []//肾功能
				},//改善生化指标评估数据
				BMIGroup: false,//小提示
				WHRGroup: false,//小提示
				BFRGroup: false,//小提示
				start: 0,//左右切换touch初始x位置
				startY: 0,//左右切换touch初始Y位置
				
				currentRate: 0,// 进度条当前进度
				rate: 0,// 进度条目标进度
				rateTitle: '低',
				rateText: '健康',
				bmi_n: [],
				fat_n: [],
				whr_n: [],
				bmi_g: [],
				fat_g: [],
				whr_g: [],
				body_g: [],
				fatVal: [],
				whrVal: [],
				bmiVal: [],
				base: {},
				fatLeft: 0,
				whrLeft: 0,
				bmiLeft: 0,
				fatMinVal: 0,
				fatMaxVal: 0,
				bomiBtn: 1,
				hasGroup: false,
			}
		},
		computed: {
			modules (){//当前结果页所拥有模块
				var list = {
					branch: true, //健康分
					biom: this.res.m06,//生化指标评估
					core: this.res.m15,//潛在疾病風險評估
					health: (this.res.base.health_type == 1 && this.res.base.age && this.res.m02) || (this.res.base.health_type == 2 && this.res.m15),//未来健康风险预测(尊享非医疗需求者会有)
					total: true,//总体健康评估和生命预测
					bodily: true,//体型评估
					costs: true,//医疗费用预测
					illness: true,//未来疾病风险预测
					noSick: this.res.base.health_type != 3 && this.res.m08,//不患病&增寿
					improve: this.res.m12 && this.res.m13 && this.res.m14//健康管理和改善方案
				};
				return list;
			},
			
			// 进度条颜色
			rateColor() {
				let color = {};
				
				if(this.res.base.health_type == 1){// 健康
					color = {
						'0%': '#b3e548',
						'100%': '#b3e548',
					}
					this.rateTitle = '低'
					this.rateText = '健康'
					this.rate = 33
				}else if(this.res.base.health_type == 2){// 亚健康
					color = {
						'0%': '#b3e548',
						'100%': '#E5CB72',
					}
					this.rateTitle = '中'
					this.rateText = '亞健康'
					this.rate = 66
				}else if(this.res.base.health_type == 3){// 医疗需求
					color = {
						'0%': '#b3e548',
						'100%': '#E77C7C',
					}
					this.rateTitle = '高'
					this.rateText = '醫療需求'
					this.rate = 100
				}
				return color;
			},
			
			// rateColor() {
			// 	let color = {};
				
			// 	if(this.res.base.health_type == 1){// 健康
			// 		color = {
			// 			'0%': '#67C289',
			// 			'100%': '#9CD488',
			// 		}
			// 	}else if(this.res.base.health_type == 2){// 亚健康
			// 		color = {
			// 			'0%': '#EFD489',
			// 			'100%': '#E5CB72',
			// 		}
			// 	}else if(this.res.base.health_type == 3){// 医疗需求
			// 		color = {
			// 			'0%': '#E8CD82',
			// 			'100%': '#E77C7C',
			// 		}
			// 	}
			// 	return color;
			// },
			
			
			
		},
		watch:{
			$route (){
				this.id = this.$route.params.id;
			},// 路由变化更新id
			id (){
				resultCommit(this.id).then( data => {
					if(data.code == 200){
						var result = JSON.parse(window.atob(data.data));
						console.log("result",result);
						this.res = result;
						
						this.rate = parseFloat(this.res.base.mlev ? this.res.base.mlev : 0);
						this.scoreVal = parseFloat(this.res.base.mlev ? this.res.base.mlev : 0);
						result.base.health_type = result.base.categ == 'HEA1' ? 1 : result.base.categ == 'HEA2' ? 2 : result.base.categ == 'HEA3' ? 3 : '';
						
						this.base = result.base;
						console.log(result);
						this.bmi_n = result.m05.bmi_n;
						this.fat_n = result.m05.fat_n;
						this.whr_n = result.m05.whr_n;
						this.bmi_g = result.m05.bmi_g;
						this.fat_g = result.m05.fat_g;
						this.whr_g = result.m05.whr_g;
						this.body_g = result.m05.body_g;
						this.fatVal = result.m05.fat_n[2].split('~');
						this.bmiVal = result.m05.bmi_n[2].split('~');
						this.whrVal = result.m05.whr_n[2].split('<');
						this.fatMinVal = this.fatVal[0].split('%')[0];
						this.fatMaxVal = this.fatVal[1].split('%')[0];
						
						
						this.handleBmiLeft(result.base.bmi);
						this.handleFatLeft(result.base.fat);
						this.handleWhrLeft(result.base.whr);
						
						//显示当前模块图表
						this.showChart();
					}else{
						Toast.fail(data.msg);
						this.$router.back(-1);
					}
				})
			}// id变化,重新获取数据
		},
		mounted (){
			// 初次进入回到顶部
			window.scrollTo(0,0);

			document.title = '评估结果';
			this.id = this.$route.params.id;// 当前结果id

			// 模块列表吸顶距离
			this.$nextTick(() => {
				this.offsetTop = document.querySelector('html').style.fontSize.replace('px','')*0.88;
				
				//切换模块完成后合并为一个,否则页面高度和切换可能会出现问题
				document.querySelector('.van-swipe__track').addEventListener('transitionend', () => {
					this.nextActive = this.active;
				});
			});
			
		},
		methods: {
			handleBmiLeft (val) {
				let lineV = this.bmiVal[1] - this.bmiVal[0];
				let rang = lineV * 3;
				let minV = this.bmiVal[0] - lineV;
				let maxV = Number(this.bmiVal[1]) + Number(lineV);
				let left = 0
				if(val <= minV) {
					left = 0
				} else if((val > minV) && (val < this.bmiVal[0])) {
					left = ((val - minV) / rang) * 100
				} else if ((val > this.bmiVal[0]) && (val < this.bmiVal[1])) {
					left = ((val - minV) / rang) * 100
				} else if ((val > this.bmiVal[1]) && (val < maxV)) {
					left = (((val - minV) / rang) * 100 > 96 ? 96 : ((val - minV) / rang) * 100 )
				} else {
					left = 96
				}
				
				this.bmiLeft = left;
			},
			handleFatLeft (val) {
				let fatVal = this.fatVal ? this.fatVal : [];
				// 284
				let lineV = fatVal[1].split('%')[0] - fatVal[0].split('%')[0];
				let rang = lineV * 3;
				let minV = fatVal[0].split('%')[0] - lineV;
				let maxV = Number(fatVal[1].split('%')[0]) + Number(lineV);
				let left = 0;
				if(val <= minV) {
					left = 0
				} else if(val > minV && val < fatVal[0].split('%')[0] ) {
					left = ((val - minV) / rang) * 100
				} else if (val > fatVal[1].split('%')[0] && val < maxV) {
					left = (((val - minV) / rang) * 100 > 96 ? 96 : ((val - minV) / rang) * 100 )
				} else if(val > fatVal[0].split('%')[0] && val < fatVal[1].split('%')[0]) {
					left = (((val - minV) / rang) * 100 > 96 ? 96 : ((val - minV) / rang) * 100 )
				} else {
					left = 96
				}
				
				this.fatLeft = left
			},
			handleWhrLeft (val) {
				let lineV = this.whrVal[1];
				let rang = lineV * 2;
				let left = 0
				if(val <= 0) {
					left = 0
				} else if(val >= rang) {
					left = 96
				} else {
					left = ((val/rang) * 100) > 96 ? 96 : ((val/rang) * 100 )
				}
				this.whrLeft = left
			},
			lookMore() {
				this.$router.push({
					path: '/newgoods/editQuestion/'+this.id,
					query: {
						isedit: this.res.base.isedit?this.res.base.isedit:0
					}
				});
			},
			
			checkModule (module){
				this.active = module;
				window.scrollTo(0,(document.querySelector('.list').offsetTop - document.querySelector('.list-title').offsetHeight));
				this.showChart();
				
				this.$refs.swp.swipeTo(this.getModules().indexOf(module));
			},//切换模块列表
			
			clickCheckModule (module){
				this.nextActive = this.active;
				this.checkModule(module)
			},//点击模块名切换模块
			
			slide (index){
				this.checkModule(this.getModules()[index]);
			},//滑动切换模块
			
			touchStart (e){
				this.nextActive = this.active;
				this.start= e.changedTouches[0].pageX;
				this.startY= e.changedTouches[0].pageX;
			},
			
			checkMove (e){
				let move = e.changedTouches[0].pageX,
					arr = this.getModules(),
					nowIndex = arr.indexOf(this.nextActive),
					nexIndex;
				
				//是否是上下滑动(防止上下滑动触发左右滑动事件) 上下滑动超过10单位定位上下滑动
				let upDown = Math.abs(e.changedTouches[0].screenY-this.startY)>10?true:false;
				if(!upDown){
					if(this.start>move && nowIndex<arr.length-1){
						nexIndex = nowIndex+1;
					}else if(this.start<move && nowIndex>0){
						nexIndex = nowIndex-1;
					}else{
						nexIndex = nowIndex;
					}
					
					this.active = arr[nexIndex];
				}
			},//判断左滑动还是右滑动,便于显示下一模块,使切换更平滑
			
			getModules (){
				let arr = [];
				for(let i in this.modules){
					this.modules[i]?arr.push(i):'';
				}
				return arr;
			},//获取当前所有模块名称数组
			
			chartOn (type, index, item, e){
				var chartDom;
				if(e.target.className.indexOf('van-icon') != -1){
					chartDom = e.target.parentNode.parentNode.nextElementSibling;
				}else {
					chartDom = e.target.parentNode.nextElementSibling;
				}
				if(chartDom.className.indexOf(type) == -1){
					chartDom.className += ' '+type+index;
				}
				if(chartDom.className.indexOf('qjc-hide') == -1){
					chartDom.className += ' qjc-hide';
				}else{
					chartDom.className = chartDom.className.replace('qjc-hide','');
				}
				if(type == 'risk'){//疾病
					this.illnessChart('.'+type+index, item.person_rates, item.compare_rates);
				}else if(type == 'cancer'){ //癌症
					this.cancerChart('.'+type+index, item.devg, item.rates);
				}
			},//未来疾病展示图表 参数1：当前所属图表(疾病或癌症)
			
			arrMax (arr){
				var max = arr[0];//默认取第一个
				for(var i=1;i<arr.length;i++){//从第二个开始筛选
					arr[i]>max?max=arr[i]:'';
				}
				return max;
			},//数组中最大值
			
			accMul,
			
			//生化指标表格数据筛选分类 参数：当前所在模块
			biomClassify (module){
				var CBC = ['WBC','HGB','PLT'],//血液常规
					bloodGlucose = ['FG','HBA1C'],//血糖
					bloodLipids = ['CHOL','LDLC','HDLC','TG'],//血脂肪
					ALT = ['GOT','GPT','ALP','ALB'],//肝胆功能
					renalFunc = ['CRE','UA','EGFR'];//肾功能
				
				var nowData,data;
				if(module == 'biom'){//生化指标评估模块
					nowData = this.biomData = {
						CBC: [],//血液常规
						bloodGlucose: [],//血糖
						bloodLipids: [],//血脂肪
						ALT: [],//肝胆功能
						renalFunc: []//肾功能
					};
					data = this.res.m06;
					let hasGroup = false;
					hasGroup = data[0].group_p ? true : false
					this.hasGroup = hasGroup
				}else{//生化指标改善模块 
					nowData = this.improveBiomData = {
						CBC: [],//血液常规
						bloodGlucose: [],//血糖
						bloodLipids: [],//血脂肪
						ALT: [],//肝胆功能
						renalFunc: []//肾功能
					};
					data = this.res.m13;
				}
				
				for(var i=0;i<data.length;i++){
					if(CBC.indexOf(data[i].item) != -1){
						nowData.CBC.push(data[i]);
					}else if(bloodGlucose.indexOf(data[i].item) != -1){
						nowData.bloodGlucose.push(data[i]);
					}else if(bloodLipids.indexOf(data[i].item) != -1){
						nowData.bloodLipids.push(data[i]);
					}else if(ALT.indexOf(data[i].item) != -1){
						nowData.ALT.push(data[i]);
					}else if(renalFunc.indexOf(data[i].item) != -1){
						nowData.renalFunc.push(data[i]);
					}
				}
			},
			
			//未来健康风险预测-亚健康(四高)数据筛选分类
			threeHighsClassify (){
				var three = ['A01','A02','A16' , 'A05'];
				return this.res.m15.filter(item => three.indexOf(item.dis) != -1);
			},
			
			weightChart(sugweight, weeks){
				var option = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							if(params[0].dataIndex == 0) {
								return params[0].name + ':<br>' + '體重為' + params[0].data + 'kg'
							} else {
								return params[0].name + ':<br>' + '體重改善目標為' + params[0].data + 'kg'
							}
						},
					},
					grid: {
						top: '15%',
						bottom: '14%',
						left: '10%',
						right:'8%'
					},
					xAxis: {
						axisLabel: {
							interval: 0,
							color: '#777F8F'
						},
						boundaryGap: false,
						// splitNumber: 5,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: 'true',
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						data: (() => {
							let lists = []
							weeks.map((item, index) => {
								if(index == 0) {
									item = '當前'
								} else {
									item =  '第' +item+ '周'
								}
								lists.push(item)
							})
							return lists
						})()
					},
					yAxis: {
						name: 'kg',
						type: 'value',
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						}
					},
					series: [
						{
							type: 'line',
							symbol: 'emptyCircle',
							areaStyle: {
								opacity: .2,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
								        offset: 0, color: '#6883FB'
								    },  {
								        offset: 1, color: '#fff' 
								    }]
								)
							},
							smooth: false,
							data: sugweight
						}
					],
					color: ['#6883FB','#65D448']
				}
				this.drawChat('.weight_chart', option);
			},
			whrChart(sugwhr, weeks){
				var option = {
					tooltip: {
						trigger: 'axis',
						formatter: function(params) {
							if(params[0].dataIndex == 0) {
								return params[0].name + ':<br>' + '腰臀比為' + params[0].data
							} else {
								return params[0].name + ':<br>' + '腰臀比改善目標為' + params[0].data
							}
						},
					},
					grid: {
						top: '15%',
						bottom: '14%',
						left: '10%',
						right:'8%'
					},
					xAxis: {
						axisLabel: {
							interval: 0,
							color: '#777F8F'
						},
						boundaryGap: false,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: 'true',
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						data: (() => {
							let lists = []
							weeks.map((item, index) => {
								if(index == 0) {
									item = '當前'
								} else {
									item =  '第' +item+ '周'
								}
								lists.push(item)
							})
							return lists
						})()
					},
					yAxis: {
						name: '',
						type: 'value',
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						}
					},
					series: [
						{
							type: 'line',
							symbol: 'emptyCircle',
							areaStyle: {
								opacity: .2,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
								        offset: 0, color: '#6883FB'
								    },  {
								        offset: 1, color: '#fff' 
								    }]
								)
							},
							data: sugwhr
						}
					],
					color: ['#6883FB','#65D448']
				}
				this.drawChat('.whr_chart', option);
			},
			
			// 当前模块显示图表
			showChart (){
				this.$nextTick(() => {
					switch (this.active){
						case 'biom': //常见疾病风险倍率
							this.biomClassify(this.active);//生化指标评估表格
							break;
						
						case 'core': //常见疾病风险倍率
							this.res.m15?this.BiochemicalChart(this.res.m15):'';
							break;
						
						case 'total': //总体健康评估和生命预测
							this.totalChart(this.res.m01);
							break;
							
						case 'costs': //医疗费用预测
							this.costsChart(this.res.m10.person_yearsfee,(this.res.m10.compare_yearsfee && this.res.base.health_type != "1") ? this.res.m10.compare_yearsfee : []);
							break;
							
						case 'improve': //健康管理和改善方案
							this.res.m13? this.biomClassify(this.active) : '';//改善生化指标
							this.res.m12 ? this.weightChart(this.res.m12.sugweight, this.res.m12.weeks) : '';
							this.res.m12 ? this.whrChart(this.res.m12.sugwhr, this.res.m12.weeks) : '';
							break;
					}
				})
			},
			
			// 图表列表
			BiochemicalChart (data){
				let list = [];
				let illList = [];
				for(let i = 0; i < data.length; i++) {
					if(data[i].sick && data[i].sick == 1) {
						illList.push(data[i])
						data[i].rate = 0.001
					}
				}
				var option = {
					grid: {
						top: 60,
						bottom: 30,
						left: '22%',
						right:'15%'
					},
					legend:{
						show:false
					},
					xAxis: {
						type: 'value',
						show: false,
						splitLine: {
							show: false
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: false
						},
						max: function (value) {
						    return value.max + 2;
						}
					},
					yAxis: [
						{
							name: '疾病類別',
							boundaryGap: false,
							nameGap: 28,
							offset: 0,
							nameTextStyle: {
								color: '#777F8F'
							},
							type: 'category',
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							data: []
						},{
							name: '患病風險倍率',
							boundaryGap: false,
							nameGap: 28,
							nameTextStyle: {
								color: '#777F8F'
							},
							type: 'category',
							axisTick: {
								show: false
							},
							splitLine: {
								show: false
							},
							axisLine:{
								show:false
							},
							data: []
						}
					],
					visualMap: {
						type:'piecewise',
						orient: 'horizontal',
						left: 'center',
						min: 10,
						max: 100,
						show:false,
						pieces: [{
							gt: 0,
							lt: 0.002,
							color: '#F25364'
						},{
							gte: 0.002,
							lt: 1,
							color: '#65D448'
						},
						{	gt: 1,
							lte: 2,
							color: 'rgba(255, 204, 26, 1)'
						},{
							gt: 2,
							color: '#F25364'
						},],
						// Map the score column to color
						dimension: 0,
					},
					series: [
						{
							type: 'bar',
							barWidth: 6,
							itemStyle: {
								color: 'rgba(31, 211, 169, 1)',
								barBorderRadius: 4
							},
							label:{
								show:true,
								position: 'right',
								// position: 'bottom',
								textStyle: {
									fontSize: 12,
									fontWeight: '700',
								},
								formatter: function(params) {
									let value = list.indexOf(params.dataIndex) > -1 ? '患病風險倍率: <' + params.value : '患病風險倍率：' + params.value
									for (let i = 0; i < illList.length; i++) {
									    if(params.dataIndex == (data.length - i - 1)) {
											value = '根據您填寫的問卷內容，您已患病'
										}
									}
									return value
								}
							},
							markLine: {
								silent: true,
								label: {
									formatter: '1.0'
								},
								symbol: 'none',
								data: [{
									xAxis:1
								}],
								lineStyle:{
									color:'#F25364'
								}
							},
							data: []
						}
					]
				}
				// 风险倍率从大到小排序
				data = [...data].reverse();
				//超出1.0疾病类型字体颜色变深
				for(var i=0;i<data.length;i++){
					if(data[i].tag) {
						list.push(i)
					}
					// option.yAxis[1].data.push(data[i].rate);// 疾病类型倍率数组
					option.series[0].data.push(data[i].rate);// 疾病风险倍率
					var yName = {
						value: data[i].devg,
						textStyle: {
							color: data[i].rate>=1?'#40444D': data[i].rate == 0.001 ? '#F25364' : '#777F8F',
							fontWeight: data[i].rate>1?'bold':'normal'
						}
					};
					option.yAxis[0].data.push(yName);
				}
				this.drawChat('.biom-chart', option);
			},//常见疾病风险倍率
			
			totalChart (data){
				//取当前年龄后一年到预期寿命年限的生命曲线数据
				var section = Math.round(data.pre_rem);// 年龄区间
				data.rates_ages = data.rates_ages.slice(0,section);// 年龄
				data.heal_rates = data.heal_rates.slice(0,section);// 健康率
				// data.lift_rates = data.lift_rates.slice(0,section);// 存活率
				data.lost_heal_ratio = data.lost_heal_ratio.slice(0,section);// 失能率
				
				var option = {
					title: {
						text: '在未來'+section+'年間，'+data.rates_ages[0]+'~'+ (parseInt(data.rates_ages[0]) + parseInt(section) - 1)+'歲的生命曲線',
						textStyle: {
							color: 'rgb(87, 93, 105)',
							fontSize: 14,
							fontWeight: '400'
						},
					},
					legend: {
						data: ['健康率', '失能率'],
						icon: 'roundRect',
						textStyle: {
							color: '#777F8F',
							fontSize: 12
						},
						top: 22,
						left: 0,
						itemWidth: 6,
						itemHeight: 6,
						itemGap: 6
					},
					grid: {
						left: 40,
						bottom: 30
					},
					color: ['rgba(92, 212, 162, 1)', 'rgba(191, 203, 255, 1)'],
					backgroundColor: '#fff',
					tooltip: {
						trigger: 'axis',
						formatter: '{b}<br>{a}: {c}%<br>{a1}: {c1}%',
						backgroundColor: '#6883FB',
						extraCssText: 'text-align: left;'
					},
					xAxis: {
						type: 'category',
						axisLine: {
							show: false
						},
						axisLabel: {
							color: '#777f8f',
							fontWeight: '300'
						},
						boundaryGap: false,
						axisTick: {
							show: false
						},
						nameLocation: 'start',
						data: (function(){
							for(var i=0,ages=[];i<data.rates_ages.length;i++){
								ages.push(data.rates_ages[i]+'岁');
							}
							return ages;
						})()
					},
					yAxis: {
						type: 'value',
						axisLine: {
							show: false
						},
						axisLabel: {
							formatter: '{value}%',
							color: '#777f8f',
							fontWeight: '300'
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#E7E9EE'
							}
						}
					},
					series: [
						{
							name: '健康率',
							type: 'bar',
							data: data.heal_rates,
							barMinWidth: 1,
							barMaxWidth: 8,
							// lineStyle: {
							// 	width: 3
							// },
							// symbol: 'none',
							// smooth: true
						},
						{
							name: '失能率',
							type: 'bar',
							data: data.lost_heal_ratio,
							barMinWidth: 1,
							barMaxWidth: 8,
							// lineStyle: {
							// 	width: 3
							// },
							// symbol: 'none',
							// smooth: true
						},
					]
				}
				this.drawChat('.total-chart', option);
			},// 总体健康评估和生命预测
			
			costsChart (tenYears, tenYearsGroup){
				var option = {
					// title: {
					// 	text: '未来'+ tenYears.length +'年主要医疗费用演化趋势',
					// 	textStyle: {
					// 		color: '#40444D',
					// 		fontSize: 12
					// 	},
					// 	bottom: 15,
					// 	left: 'center'
					// },
					legend: {
						orient: 'horizontal',
						data: ['您的醫療費用預測','同族群的醫療費用預測'],
						icon: 'roundRect',
						textStyle: {
							color: '#777F8F',
							fontSize: 12
						},
						// // top: 10,
						bottom: 10,
						// left: '40%',
						itemWidth: 8,
						itemHeight: 8,
						itemGap: 10
					},
					backgroundColor: '#fff',
					grid: {
						left: '18%',
						right: 0
					},
					tooltip: {
						trigger: 'axis',
						formatter: tenYearsGroup.length > 0 ? function(params) {
							if(params.length == 1) {
								return `第${params[0].axisValue}年<br>${params[0].seriesName}: ${params[0].value}港元`
							} else {
								return `第${params[0].axisValue}年<br>${params[0].seriesName}: ${params[0].value}港元<br>${params[1].seriesName}: ${params[1].value}港元`
							}
						} : '<br>第{b0}年',
						backgroundColor: '#FCFDFF',
						confine: true,
						textStyle: {
							color: '#666',
							fontSize: 12,
							fontWeight: 300
						},
						extraCssText: 'box-shadow: 0px 10px 20px 0px rgba(136,144,152,0.14);text-align: left;'
					},
					xAxis: {
						type: 'category',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						data: (() => {
							var x = [];
							for(var i=0;i<tenYears.length;i++){
								x.push(i+1);
							}
							return x;
						})()
					},
					yAxis: {
						name: 'HK $     ',
						nameTextStyle: {
							color: '#777F8F'
						},
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						axisTick: {
							show: false
						},
					},
					series: [
						{
							type: 'bar',
							name: '您的醫療費用預測',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(
								  0, 0, 0, 1,//渐变色在下面修改，这里是透明度
								  [{
									  offset: 0,
									  color: 'rgba(83, 131, 255, 1)',
									  borderRadius: 8
									},
									{
									  offset: 1,
									  color: 'rgba(161, 188, 255, 1)',
									  
									}
								  ]
								),
								barBorderRadius: 8
							},
							barWidth: 8,
							data: []
						},
						{
							type: 'bar',
							name: '同族群的醫療費用預測',
							itemStyle: {
								normal: {
									color: '#E6E8EB',
									barBorderRadius: 8
								},
								emphasis: {
									color: '#E6E8EB',
									opacity: '0.8',
									barBorderRadius: 8
								},
							},
							barWidth: 8,
							data: [],
							
						}
					]
				}
				// 是否有同族群展示
				if(tenYearsGroup){

				}
				if(tenYearsGroup.length<1){
					option.legend.data.pop();
					option.series.pop();
					option.tooltip.formatter += '<br>{a0}:{c0}港元';
				}else{
					for(var p=0;p<tenYearsGroup.length;p++){
						// 费用计算
						option.series[1].data.push(tenYearsGroup[p]);
					}
					// option.tooltip.formatter += '<br>{a0}:{c0}元<br>{a1}:{c1}元';
				}
				for(var q=0;q<tenYears.length;q++){
					// 费用计算
					option.series[0].data.push(tenYears[q]);
				}
				this.drawChat('.costs-chart', option);
			},// 医疗费用预测  参数一：十年费用预测  参数二：同族费用预测(可能为空)
			
			illnessChart (el, person, compare){
				var option = {
						tooltip: {
							trigger: 'axis',
							formatter: '{b}'
						},
						legend: {
							data: ['個人患病概率','同族群患病概率'],
							icon: 'roundRect',
							textStyle: {
								color: '#777F8F',
								fontSize: 12
							},
							itemWidth: 10,
							itemHeight: 4,
							itemGap: 20
						},
						xAxis: {
							boundaryGap: false,
							axisLabel: {
								color: '#777F8F'
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								show: 'true',
								lineStyle: {
									color: '#f5f5f5'
								}
							},
							data: (function(){
								var arr = [];
								for(var i=0;i<person.length;i++){
									arr.push('第'+(i+1)+'年');
								}
								return arr;
							})()
						},
						yAxis: {
							name: '%',
							type: 'value',
							axisLabel: {
								color: '#777F8F'
							},
							axisLine: {
								show: false
							},
							axisTick: {
								show: false
							},
							splitLine: {
								lineStyle: {
									color: '#f5f5f5'
								}
							}
						},
						series: [
							{
								name: '個人患病概率',
								type: 'line',
								data: person
							},
							{
								name: '同族群患病概率',
								type: 'line',
								data: []
							}
						],
						color: ['#6883FB','#65D448']
					};
				option.tooltip.formatter += '<br>個人患病概率:{c}%';
				if(compare && compare.length > 0){
					option.tooltip.formatter += '<br>同族群患病概率:{c1}%';
					option.series[1].data = compare;
				}else{
					option.legend.data.pop();
					option.series.pop();
				}
				this.drawChat(el, option);
			},//未来疾病风险预测 参数：1：疾病列表下标(用了区分图标class) 2:疾病名称 3：疾病列表数据 4：同族群列表数据
			
			cancerChart (el,name,data){
				var option = {
					tooltip: {
						trigger: 'axis',
						formatter: '{b}的疾病風險<br>您患'+name+'的倍率為:{c}倍'
					},
					xAxis: {
						axisLabel: {
							color: '#777F8F'
						},
						boundaryGap: false,
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							show: 'true',
							lineStyle: {
								color: '#f5f5f5'
							}
						},
						data: ['未來5年','未來10年','未來15年','未來20年']
					},
					yAxis: {
						name: '倍',
						type: 'value',
						axisLabel: {
							color: '#777F8F'
						},
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						splitLine: {
							lineStyle: {
								color: '#f5f5f5'
							}
						}
					},
					series: [
						{
							type: 'line',
							symbol: 'none',
							areaStyle: {
								opacity: .2,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1,[{
								        offset: 0, color: '#6883FB'
								    },  {
								        offset: 1, color: '#fff' 
								    }]
								)
							},
							data: data
						}
					],
					color: ['#6883FB','#65D448']
				}
				this.drawChat(el, option);
			},//癌症风险图表
			
			drawChat(el, option){
				window[el] = echarts.init(document.querySelector(el));
				// option.animationDuration = 1000;
				window[el].setOption(option,true);
				window.onresize = window[el].resize;
			}// 绘制图表通用
		}
	}
</script>

<style scoped>
	.person >>> .van-grid-item__content{
		padding: 0;
	}
	
	.total >>> .van-grid-item__content{
		background: #F5F6FA;
		border-radius: 0.08rem;
		height: 2.26rem;
		padding: 0.24rem 0.2rem;
	}
	
	.tip_p {
		position: relative;
		padding-left: 0.1rem;
		text-align: left;
	}
	.tip_p::before {
		position: absolute;
		content: '';
		top: 0.12rem;
		left: -0.04rem;
		width: 0.08rem;
		height: 0.08rem;
		border-radius: 50%;
		background-color: #6883FB;
	}
	.tip_p_wrap {
		padding: 0.24rem;
		text-align: left;
	}
	
	.three_list .costs_top {
		padding: 0.24rem 0.24rem 0;
		margin-bottom: 0.1rem;
	}
	.three_list .costs_top::before {
		top: 0.36rem;
		left: 0;
	}
</style>
<style lang="scss" scoped>
	@import "../../assets/iconfont/iconfont.css";
	
	.van-nav-bar{
		background-color: #F5F7FA;
	}
	.result{
		margin-top: 0.88rem;
		padding-top: 0.24rem;
		color: #777F8F;
		background-color: #fff;
		
		&::before{
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			// height: 4.33rem;
		}
		// 各套餐文字颜色
		$health: #44B46E;// 健康
		$subhealth: #D1B83C;// 亚健康
		$medical: #CC5656;// 医疗需求
		&.type-color1{// 健康
			&::before{
				background: linear-gradient(#F5F7FA, rgba(255, 255, 255, 0))
				// background: linear-gradient(0deg, #F5F7FA 0%, rgba(255, 255, 255, 0) 100%);
				// background: linear-gradient(0deg, rgba(235, 247, 239, 0) 0%, #EBF7EF 100%);
			}
			.mlev{
				color: $health;
			}
			.t-belong{
				span{
					color: $health;
				}
			}
		}
		&.type-color2{// 亚健康
			&::before{
				background: linear-gradient(#F5F7FA, rgba(255, 255, 255, 0))
				// background: linear-gradient(0deg, #F5F7FA 0%, rgba(255, 255, 255, 0) 100%);
				// background: linear-gradient(0deg, rgba(247, 244, 235, 0) 0%, #F7F4EB 100%);
			}
			.mlev{
				color: $subhealth;
			}
			.t-belong{
				span{
					color: $subhealth;
				}
			}
		}
		&.type-color3{// 医疗需求
			&::before{
				background: linear-gradient(#F5F7FA, rgba(255, 255, 255, 0))
				// background: linear-gradient(0deg, #F5F7FA 0%, rgba(255, 255, 255, 0) 100%);
				// background: linear-gradient(0deg, rgba(247, 235, 235, 0) 0%, #F7EBEB 100%);
			}
			.mlev{
				color: $medical;
			}
			.t-belong{
				span{
					color: $medical;
				}
			}
		}
		
		// 个人信息
		
		.infomation-bg {
			position: relative;
			margin-bottom: 0.4rem;
			z-index: 999;
		}
		.infomation-new {
			position: relative; 
			margin: 0 0.24rem;
			padding-top: 0.24rem;
			overflow: hidden;
			box-shadow: 0px 0.06rem 0.5rem 0px #edf1f6;
			
			
			&::after{
				// content: '';
				// display: block;
				// clear: both;
				content: '';
			    width: 260%;
				height: 100%;
				position: absolute;
				left: -80%;
				top: 0;
				z-index: -1;
				border-radius: 0 0 50% 50%;
				background: #ffffff;
				overflow: hidden;
				// box-shadow: 0px 0.25rem 0.5rem 0px #EDF1F6;
				box-shadow: 0 0.06rem 0.5rem 0 #edf1f6;
			}
			.circle-wrap {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				
				.circle-tip {
					position: absolute;
					bottom: 0;
					height: 0.5rem;
					line-height: 0.5rem;
					padding: 0 0.1rem;
					font-size: 0.2rem;
					color: #777F8F;
					border-radius: 0.2rem;
					background-color: #ffffff;
					z-index: 3;
					// transform: rotate(180deg);
				}
			}
			
			.circle-dashed {
				position: absolute;
				z-index: 2;
				width: 2.5rem;
				height: 2.5rem;
				// background: linear-gradient(to bottom, #ff1481, #ff5d3e);
				border: 0.1rem dashed #ffffff;
				border-radius: 50%;
				transform: rotate(180deg);
				
			}
			.infomation-tip {
				padding-bottom: .1rem;
				p{
					font-size: 0.2rem;
					color: #777F8F;
				}
				span {
					color: #6681FA;	
				}
			}
			.danger{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 0.36rem;
				width: 100%;
				transform: rotate(180deg);
				.mlev{
					transform: rotate(180deg);
					
					span{
						font-size: 0.48rem;
					}
				}
			}
			&>div{
				p{
					margin-bottom: 0.24rem;
				}
				
				ul{
					display: flex;
					flex-wrap: wrap;
					width: 4.1rem;
					
					li{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 1.9rem;
						height: 0.56rem;
						background-color: #F5F6FA;
						border-radius: 0.28rem;
						margin-bottom: 0.16rem;
						margin-right: 0.24rem;
					}
				}
			}
			li:nth-child(3n) {
				margin-right: 0;
			}
			.hint{
				font-size: 0.2rem;
				color: #777F8F;
				margin-top: 0.06rem;
			}
			
			.circle-level {
				font-size: 0.48rem;
				font-weight: 800;
				color: #40444D;
			}
			.circle-level-text {
				font-size: 0.24rem;
				color: #777F8F;
			}
			.shape-tag-wrap {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				width: 100%;
				padding: 0.47rem 0.4rem 0;
				align-content: space-around;
				justify-content: center
				
				
			}
			.tag-b{
				color: #40444D;
			}
			
		}
		
		
		
		
		.infomation{
			position: relative;
			margin: 0 0.24rem 0.24rem;
			padding-top: 0.36rem;
			border-radius: 0.1rem;
			background-color: #fff;
			
			&::after{
				content: '';
				display: block;
				clear: both;
			}
			.danger{
				display: flex;
				justify-content: center;
				align-items: center;
				margin: 0 0.36rem;
				
				.mlev{
					span{
						font-size: 0.48rem;
					}
				}
			}
			&>div{
				p{
					margin-bottom: 0.24rem;
				}
				
				ul{
					display: flex;
					flex-wrap: wrap;
					width: 4.1rem;
					
					li{
						display: flex;
						justify-content: center;
						align-items: center;
						width: 1.9rem;
						height: 0.56rem;
						background-color: #F5F6FA;
						border-radius: 0.28rem;
						margin-bottom: 0.12rem;
						margin-right: 0.12rem;
					}
				}
			}
			.hint{
				font-size: 0.2rem;
				color: #777F8F;
				margin-top: 0.06rem;
			}
		}
		.person_wrap {
			padding: 0 0.24rem;
			margin-bottom: 0.16rem;
		}
		.person{
			padding: 0.4rem 0.1rem 0.4rem;
			border-radius: 0.12rem;
			border: 1px solid #d3d5de;
			
			
			dl{
				margin: 1rem auto;
				
				dt{
					width: 1.7rem;
					color: #999999;
				}
				b{
					font-size: 0.4rem;
				}
			}
		}
		// 结果列表
		.list{
			background-color: #F5F7FA;
			
			//滑动切换
			.van-swipe{
				.van-swipe-item{
					min-height: 1px;
				}
			}
			
			// 生化指标评估
			.biom{
				padding: 0.24rem;
				
				p{
					font-size: 0.2rem;
					color: #6A7180;
					line-height: 0.27rem;
					margin-bottom: 0.48rem;
					margin-top: 0.2rem;
				}
				.bomi_top_cell {
					display: flex;
					align-items: center;
					padding: 0 0.12rem;
					margin: 0.24rem 0 0.08rem;
				}
				.bomi_title {
					flex: 1;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 600;
					font-size: 0.26rem;
					line-height: 0.34rem;
				}
				.bomi_res {
					flex: 1;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 0.26rem;
					line-height: 0.34rem;
				}
				.bomi_v {
					// flex: 1;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 0.26rem;
					line-height: 0.34rem;
				}
				.bomi_btn_v {
					// flex: 1;
					width: 1.2rem !important;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 0.26rem;
					line-height: 0.34rem;
					text-align: center;
				}
				.bomi_lc {
					flex: 1;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 0.26rem;
					line-height: 0.34rem;
				}
				.bomi_group {
					// flex: 1;
					color: rgba(59, 69, 73, 1);
					font-family: PingFang SC;
					font-weight: 400;
					font-size: 0.26rem;
					line-height: 0.34rem;
				}
				
				.bomi_item {
					display: flex;
					align-items: center;
					padding: 0.16rem 0.12rem;
					background-color: rgba(244, 246, 252, 1);
					
					&:nth-child(4){
						border-radius: 0.08rem 0.08rem 0 0 ;
					}
					&:nth-child(6){
						border-radius: 0 0 0.08rem 0.08rem;
					}
					&:nth-child(8){
						border-radius: 0.08rem 0.08rem 0 0 ;
					}
					&:nth-child(9){
						border-radius: 0 0 0.08rem 0.08rem;
					}
					&:nth-child(11){
						border-radius: 0.08rem 0.08rem 0 0 ;
					}
					&:nth-child(14){
						border-radius: 0 0 0.08rem 0.08rem;
					}
					&:nth-child(16){
						border-radius: 0.08rem 0.08rem 0 0 ;
					}
					&:nth-child(19){
						border-radius: 0 0 0.08rem 0.08rem;
					}
					&:nth-child(21){
						border-radius: 0.08rem 0.08rem 0 0 ;
					}
					&:nth-child(23){
						border-radius: 0 0 0.08rem 0.08rem;
					}
					
					.c-low{
						font-weight: 500;
						color: #3AC316 !important;
					}
					.c-high{
						font-weight: 500;
						color: #FF3131 !important;
					}
					.c-nice{
						font-weight: 500;
						color: #3AC316 !important;
					}
					.qjc-c-primary{
						font-weight: 500;
					}
					
					.bomi_item_title {
						flex: 1;
						color: rgba(59, 69, 73, 1);
						font-family: PingFang SC;
						font-size: 0.26rem;
						line-height: 0.34rem;
					}
					.bomi_item_res {
						// display: flex;
						flex: 1;
						color: rgba(59, 69, 73, 1);
						font-family: PingFang SC;
						font-size: 0.26rem;
						line-height: 0.34rem;
						span{
							margin-left: 0.08rem
						}
					}
					
					.bomi_item_v {
						// flex: 1;
						width: 1.2rem !important;
						color: rgba(59, 69, 73, 1);
						font-family: PingFang SC;
						font-size: 0.26rem;
						line-height: 0.34rem;
						text-align: center;
						
					}
					.bomi_item_group {
						// flex: 1;
						width: 1.2rem !important;
						color: rgba(59, 69, 73, 1);
						font-family: PingFang SC;
						font-size: 0.26rem;
						line-height: 0.34rem;
						text-align: center;
					}
				}
				
				
				
				table{
					font-size: 0.2rem;
					line-height: 0.28rem;
					border-radius: 0.08rem;
					border: 0.01rem solid #D3D5DE;
					overflow: hidden;
					
					.group-v{
						position: relative;
						
						&::before{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 2000%;
							border-left: 0.01rem dashed #D3D5DE;
							content: '';
						}
					}
					th,li{
						height: 0.67rem;
						padding: 0.2rem 0.15rem;
					}
					li{
						display: flex;
						align-items: center;
						span{
							font-weight: 600;
							margin-right: 0.05rem;
						}
					}
					tr>td:nth-child(2){
						width: 1.7rem;
					}
					tr>td:nth-child(4),tr>td:nth-child(5){
						width: 1.3rem;
					}
					tr>td:first-child{
						width: 0.45rem;
						padding: 0 0.12rem;
						border-right: 0.01rem solid #D3D5DE;
					}
					tr>td:last-child{
						width: 1.7rem;
						padding-left: 0.15rem;
					}
					tr>th:last-child{
						padding-left: 0.3rem;
					}
					tr:nth-child(even){
						background-color: #F5F8FC;
						
						td{
							border-top: 0.01rem solid #D3D5DE;
							border-bottom: 0.01rem solid #D3D5DE;
							
							&:first-child{
								border-left: 0.01rem solid #D3D5DE;
							}
							&:last-child{
								border-right: 0.01rem solid #D3D5DE;
							}
						}
					}
					.c-low{
						font-weight: 500;
						color: #3AC316;
					}
					.c-high{
						font-weight: 500;
						color: #FF3131;
					}
					.c-nice{
						font-weight: 500;
						color: #3AC316;
					}
					.qjc-c-primary{
						font-weight: 500;
					}
				}
				.chart-t{
					margin: 0.4rem 0 0.24rem;
				}
				
			}
			.core{
				padding: 0.24rem;
				
				.biom-chart{
					height: 7.925rem;
					background-color: #F5F8FC;
					border-radius: 0.08rem 0.08rem 0 0;
					margin-top: 0.3rem;
				}
			}	
			// 未来健康风险预测
			.health{
				li{
					padding: 0.24rem;
					border-bottom: 0.1rem solid #F5F7FA;
					
					h3{
						margin: 0.2rem 0 0.3rem;
					}
					dl{
						background-color: #F5F8FC;
						border-radius: 0.08rem;
						padding: 0.3rem 0;
						line-height: 0.4rem;
						
						dd{
							span{
								font-size: 0.34rem;
							}
						}
					}
					p{
						margin: 0.24rem 0;
						font-weight: 300;
						line-height: 0.48rem;
						
						span{
							font-weight: 400;
						}
					}
				}
				// 健康者
				.health1{
					padding: 0.36rem 0.26rem;
					
					p{
						font-size: 0.28rem;
						color: #6A7180;
						font-weight: 300;
						line-height: 0.56rem;
						margin-bottom: 0.4rem;
					}
					div{
						display: flex;
						justify-content: space-between;
						background-color: #F5F8FC;
						padding: 0.22rem 0.5rem;
						margin-bottom: 0.28rem;
						
						dl{
							width: 1.9rem;
							border-radius: 0.08rem;
							
							b{
								font-size: 0.48rem;
							}
							dt{
								line-height: 0.28rem;
								font-weight: 500;
							}
						}
					}
				}
			}
					
			// 标题列表
			.list-title{
				height: 0.76rem;
				line-height: 0.75rem;
				display: flex;
				justify-content: flex-start;
				border-bottom: 0.01rem solid #D3D5DE;
				background-color: #F5F7FA;
				overflow-x: auto;
				
				li{
					padding: 0 0.2rem;
					font-weight: 500;
					background: transparent;
					transition: all .3s;
					
					&.active{
						color: #fff;
						background: linear-gradient(5deg, #6681FA 0%, #8A9DF7 100%);
					}
				}
			}
			
			// 总体健康和生命预测
			.total{
				padding: 0.36rem 0.1rem 0.1rem;
				
				.total-t{
					.van-grid-item{
						p{
							margin-bottom: 1rem;
							height: 0.24rem;
							line-height: 0.24rem;
							
							b{
								font-weight: 400;
							}
						}
						div{
							display: flex;
							justify-content: space-between;
							align-items: center;
							
							span{
								font-size: 0.4rem;
							}
						}
					}
				}
				.total_title {
					position: relative;
					padding-left: 0.14rem;
					color: rgba(41, 48, 51, 1);
					font-family: PingFang SC;
					font-weight: 500;
					font-size: 0.36rem;
					line-height: 0.36rem;
					text-align: left;
					
					&::before {
						position: absolute;
						left: -0.12rem;
						top: 0.12rem;
						content: '';
						width: 0.16rem;
						height: 0.12rem;
						border-radius: 0 0.04rem 0.04rem 0;
						background: rgba(82, 114, 255, 1);
					}
				}
				.total-chart{
					height: 6.43rem;
					margin: 0.2rem 0.1rem;
				}
				.hint{
					padding: 0 0.1rem;
					font-weight: 400;
					line-height: 0.48rem;
					
					.qjc-c-primary{
						font-weight: 500;
					}
				}
				
				
				.total_item {
					position: relative;
					padding: 0.36rem 0.14rem 0.24rem;
					width: 100%;
					margin-top: 0.12rem;
					// background-color: #fff;
					
					
					&::before {
						position: absolute;
						top: 0;
						left: -0.1rem;
						content: '';
						width: 7.50rem;
						height: 0.12rem;
						background-color: #f3f4fa;
					}
					
					.total_item_cell {
						display: flex;
						align-items: center;
						justify-content: space-between;
						width: 100%;
						
						.total_item_title {
							color: rgba(41, 48, 51, 1);
							font-family: PingFang SC;
							font-weight: 500;
							font-size: 0.30rem;
							line-height: 0.30rem;
						}
						
						.total_item_val {
							color: rgba(82, 114, 255, 1);
							font-family: MiSans-Demibold;
							font-size: 0.40rem;
							font-weight: 500;
							line-height: 0.40rem;
							
							span {
								font-family: PingFang SC;
								font-size: 0.22rem;
								font-weight: 400;
							}
						}
					}
					.total_item_text {
						// color: rgba(87, 93, 105, 1);
						// font-family: PingFang SC;
						// font-size: 0.26rem;
						// line-height: 0.40rem;
						// text-align: left;
						// margin-top: 0.1rem;
						
						text-align: left;
						font-size: 0.28rem;
						font-weight: 300;
						line-height: 0.56rem;
						color: #6A7180;
						margin-top: 0.1rem;
					}
				}
				
			}
			
			// 体型评估
			.bodily{
				padding: 0 0.24rem 0.8rem;
				background-color: #F5F7FA;
				
				h4{
					margin: 0.4rem 0 0.24rem;
					font-weight: 500;
				}
				.res{
					table{
						padding: 0.2rem 0.3rem;
						border-radius: 0.08rem;
						color: #6A7180;
						
						thead{
							line-height: 0.98rem;
							
							th{
								font-weight: 400;
								border-bottom: 0.01rem solid #777F8F;
								
								&.qjc-ftw-b{
									font-weight: 500;
								}
								&:first-child{
									width: 1rem;
									position: relative;
								}
							}
							.BFR-t{
								position: absolute;
								left: 0;
								bottom: 8%;
								text-align: left;
								width: 0.6rem;
								line-height: 0.26rem;
							}
							.BMI-t{
								position: absolute;
								top: 8%;
								right: 0;
								line-height: 0.26rem;
								
								&:before{
									content: '';
									position: absolute;
									left: -54%;
									bottom: 0;
									transform: rotateZ(45deg);
									width: 0.5rem;
									border-bottom: 0.01rem solid #777F8F;
								}
							}
						}
						tr{
							height: 1.77rem;
						}
						tr>td:first-child,
						thead>th:first-child{
							border-right: 0.01rem solid #777F8F;
						}
						.van-image{
							vertical-align: middle;
							margin-right: 0.1rem;
						}
						.img-hint{
							display: inline-block;
							font-size: 0.2rem;
							width: 0.2rem;
							line-height: 0.28rem;
							vertical-align: middle;
						}
					}
				}
				.targets{
					&>div{
						padding: 0.40rem 0.3rem 0.48rem;
						border-radius: 0.08rem;
						margin-bottom: 0.2rem;
					}
					[class^=hint]{
						height: 0.38rem;
						line-height: 0.38rem;
						padding: 0 0.1rem;
						text-align: center;
						color: #fff;
						font-size: 0.2rem;
						vertical-align: bottom;
						margin-left: 0.12rem;
						border-radius:0 0.15rem 0.15rem 0;
					}
					.hint-m{
						background-color: #2EE68D;
					}
					.hint-l{
						background-color: #338FFF;
					}
					.hint-h{
						background-color: #FF4D4D;
					}
					.targets-t{
						font-size: 0.38rem;
						line-height: 0.38rem;
						font-weight: bold;
						color: #250A0A;
						margin-bottom: 0.11rem;
					}
					.targets-t-h{
						font-size: 0.28rem;
						font-weight: 300;
						line-height: 0.56rem;
						color: #6A7180;
					}
					.iconyiwen1{
						color: #B8C0CC;
						font-size: 0.3rem;
						
						&>div{
							color: #777F8F;
							font-size: 0.24rem;
							bottom: 2em;
							left: -2.05rem;
							width: 4.2rem;
							margin-top: -0.2rem;
							padding: 0.24rem;
							line-height: 0.36rem;
							box-shadow:0 0.06rem 0.12rem 0 rgba(218,220,227,1);
							border-radius: 0.08rem;
							z-index: 9999;
							
							.arrow{
								position: absolute;
								bottom: -0.2rem;
								left: 50%;
								z-index: -1;
								width: 0.4rem;
								height: 0.4rem;
								background-color: #fff;
								transform: rotateZ(45deg)translateX(-50%);
							}
						}
					}
					.remark{
						font-size: 0.24rem;
						line-height: 0.48rem;
						color: #6A7180;
						font-weight: 300;
						margin-top: 0.56rem;
					}
					dt{
						margin: 0.56rem 0 0.3rem;
						font-size: 0.32rem;
						font-weight: 500;
						color: #333;
					}
					.group dt{
						margin-top: 0.48rem;
					}
					.line{
						position: relative;
						width: 6.3rem;
						height: 0.52rem;
						
						ul,ol{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							
							li{
								position: relative;
								width: 32%;
								height: 0.06rem;
								z-index: 1;
								
								&:not(:last-child){
									margin-right: 0.06rem;
								}
							}
						}
						ul{
							span{
								position: absolute;
								right: 0;
								bottom: -0.5rem;
								transform: translateX(50%);
							}
							li{
								&:nth-child(1){
									background-color: #338FFF;
								}
								&:nth-child(2){
									background-color: #2ee68d;
								}
								&:nth-child(3){
									background-color: #ffbb00;
								}
								&:nth-child(4){
									background-color: #ff485e;
								}
							}
						}
						ol{
							z-index: 20;
							
							li:not(:last-child)::after{
								content: '';
								position: absolute;
								top: 0;
								right: -.06rem;
								width: 0.06rem;
								height: 100%;
								background-color: #fff;
								z-index: 20;
							}
						}
						.circle{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 0.06rem;
							transition: all .3s;
							
							&.step1::before{
								background-color: #338FFF;
								box-shadow:0 0 0.06rem 0 #338FFF;
							}
							&.step2::before{
								background-color: #2ee68d;
								box-shadow:0 0 0.06rem 0 #2ee68d;
							}
							&.step3::before{
								background-color: #ffbb00;
								box-shadow:0 0 0.06rem 0 #ffbb00;
							}
							&.step4::before{
								background-color: #ff485e;
								box-shadow:0 0 0.06rem 0 #ff485e;
							}
							
							&::before{
								content: '';
								position: absolute;
								top: -0.07rem;
								left: -0.1rem;
								width: 0.2rem;
								height: 0.2rem;
								border-radius: 50%;
								border: 0.02rem solid #fff;
								box-shadow:0 0 0.06rem 0 #E1E2E6;
								background-color: #E1E2E6;
								z-index: 100;
							}
							&::after{
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								background-color: #E1E2E6;
								z-index: 10;
							}
						}
					}
					.BMI .line{
						ul,ol{
							li{
								// width: 1.52rem;
								&:nth-child(2){
									// width: 1.56rem;
								}
							}
						}
					}
					.WHR .line{
						ul,ol{
							li{
								width: 3.14rem;
								&:nth-child(2){
									width: 3.10rem;
								}
							}
						}
						ul{
							li{
								&:nth-child(1){
									background-color: #2ee68d;
								}
								&:nth-child(2){
									background-color: #ff485e;
								}
							}
						}
						.circle{
							&.step1::before{
								background-color: #2ee68d;
								box-shadow:0 0 0.06rem 0 #2ee68d;
							}
							&.step2::before{
								background-color: #ff485e;
								box-shadow:0 0 0.06rem 0 #ff485e;
							}
						}
					}
					.BFR .line{
						li{
							// width: 2.06rem;
						}
					}
					
					.BFR .group {
						// overflow: hidden;
					}
					// 不同阶段圆形图标颜色
					.BMI .you .step1 .circle::before,
					.BFR .group .step1 .circle::before{
						box-shadow:0 0 0.06rem 0 #338fff;
						background: #338fff;
					}
					.BMI .you .step2 .circle::before,
					.WHR .group .step1 .circle::before,
					.BFR .step2 .circle::before{
						box-shadow:0 0 0.06rem 0 #2ee68d;
						background: #2ee68d;
					}
					.BMI .you .step3 .circle::before,
					.BFR .group .step3 .circle::before{
						box-shadow:0 0 0.06rem 0 #ffbb00;
						background: #ffbb00;
					}
					.BMI .you .step4 .circle::before,
					.WHR .group .step2 .circle::before{
						box-shadow:0 0 0.06rem 0 #ff485e;
						background: #ff485e;
					}
				}
			}
			
			// 医疗费用预测
			.costs{
				padding: 0.48rem 0.24rem 0.8rem;
				
				ul {
					margin-top: 0.2rem;
				}
				
				li{
					position: relative;
					padding: 0.35rem 0.48rem 0.4rem 0.35rem;
					border-radius: 0.16rem;
					margin-bottom: 0.24rem;
					
					.van-image{
						position: absolute;
						right: 0;
						bottom: 0;
						margin-top: 0.08rem;
					}
					&:first-child{
						background-color: rgba(244, 246, 252, 1);
						dd{
							color: rgba(41, 48, 51, 1);
							// color: #F5495B;
						}
						b {
							
						}
					}
					&:last-child{
						background: linear-gradient(180deg, rgba(255, 244, 248, 0.9) 0%, rgba(255, 244, 244, 0.9) 100%), rgba(244, 246, 252, 1);
						dd{
							color: rgba(41, 48, 51, 1);
							// color: #4575E5;
						}
					}
					b{
						font-family: MiSans-Demibold;
						font-size: 0.40rem;
						line-height: 0.40rem;
						font-size: 0.48rem;
					}
					
					dt {
						color: rgba(122, 133, 153, 1);
						font-family: PingFang SC;
						font-size: 0.26rem;
						line-height: 0.40rem;
						margin-top: 0.08rem;
					}
				}
				.costs-chart{
					height: 7.4rem;
				}
				.costs-chart_title {
					position: relative;
					top: 0.40rem;
					z-index: 99;
					color: rgba(41, 48, 51, 1);
					font-family: PingFang SC;
					font-weight: 600;
					font-size: 0.30rem;
					line-height: 0.40rem;
					text-align: left;
				}
			}
		
			//未来疾病风险预测
			.illness{
				&>div:not(:last-child){
					border-bottom: 0.16rem solid #F0F2F5;
				}
				h3{
					font-size: 0.36rem;
					font-weight: 500;
					padding: 0.5rem 0.24rem 0;
				}
				li{
					padding: 0 0.24rem 0.5rem;
					
					&:not(:last-child){
						margin-bottom: 0.1rem;
						border-bottom: 0.01rem solid #F5F7FA;
					}
					&:first-child{
						.illness-t{
							padding-top: 0.4rem;
						}
					}
					.illness-t{
						line-height: 0.5rem;
						padding: 0.56rem 0 0.3rem;
						
						.name{
							font-size: 0.32rem;
						}
						.van-icon{
							vertical-align: middle;
						}
					}
					.chart{
						height: 5.35rem;
					}
					.prob{
						padding: 0.24rem 0;
						background-color: #F5F7FA;
						border-radius: 0.08rem;
						
						dl{
							line-height: 0.44rem;
							
							b{
								font-size: 0.34rem;
							}
							dt{
								font-weight: 500;
							}
						}
					}
					.symptom{
						color: #6A7180;
						line-height: 0.48rem;
						font-weight: 300;
						margin-top: 0.3rem;
						
						span{
							font-weight: 400;
						}
					}
					&>p.qjc-texta-l{
						font-weight: 300;
						line-height: 0.48rem;
						
						span{
							font-weight: 400;
						}
					}
				}
			}
		
			//不患病&增寿
			.noSick{
				background-color: #FFF;
				padding: 0.24rem 0.24rem 0.2rem;
				
				div{
					h3{
						padding: 0.4rem 0 0.28rem;
					}
					li{
						border-radius: 0.08rem;
						background-color: #F5F7FA;
						margin-bottom: 0.24rem;
						
						h4{
							padding: 0.3rem 0.27rem;
						}
						div{
							padding-bottom: 0.3rem;
							
							dl{
								line-height: 0.4rem;
							}
						}
						p{
							height: 0.9rem;
							line-height: 0.9rem;
							border-top: 0.04rem solid #fff;
						}
					}
				}
			}
		
			// 健康管理和改善方案
			.improve{
				padding: 0 0.24rem 2rem;
				
				.program-res{
					display: flex;
					justify-content: space-between;
					font-size: 0.2rem;
					margin: 0.4rem 0 0.6rem;
					padding: 0 !important;
					background-color: #fff !important;
				}
				
				.improve-body{
					.target{
						h3{
							padding: 0.5rem 0 0.3rem;
						}
						&>div{
							display: flex;
							justify-content: space-between;
							padding: 0.24rem 0.28rem;
							background-color: #F5F8FC;
							border-radius: 0.08rem;
							margin-bottom: 0.24rem;
							
							dt{
								margin-bottom: 0.36rem;
							}
							span{
								font-size: 0.48rem;
							}
							.van-image{
								// margin-top: 0.98rem;
								margin: auto;
							}
						}
					}
					.program{
						h4{
							margin-top: 0.8rem;
						}
						.program-res{
							display: flex;
							justify-content: space-between;
							font-size: 0.2rem;
							margin: 0.4rem 0 0.6rem;
						}
						&>div>p{
							font-size: 0.2rem;
							color: #6A7180;
							line-height: 0.3rem;
							margin: 0.1rem 0;
						}
						.eat{
							li{
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: 0.12rem 0.24rem;
								// height: 1.02rem;
								background-color: #F5F8FC;
								border-radius: 0.08rem;
								margin-bottom: 0.12rem;
								
								.van-image{
									vertical-align: text-top;
								}
								b{
									font-size: 0.2rem;
									color: #6A7180;
									
									span{
										font-size: 0.28rem;
									}
								}
							}
						}
						.sport{
							table{
								$border: 0.02rem solid #F0F2F5;
								
								font-size: 0.2rem;
								color: #333;
								border-left: $border;
								border-top: $border;
								
								th{
									font-weight: normal;
									background-color: #EEF4FF;
									line-height: 0.58rem;
									padding: 0 0.12rem;
								}
								td{
									line-height: 0.94rem;
									padding: 0 0.12rem;
									border-bottom: $border;
									border-right: $border;
									
									span{
										font-weight: bold;
										font-size: 0.28rem;
									}
								}
							}
							.symptom{
								font-size: 0.2rem;
								line-height: 0.32rem;
								margin: 0.24rem 0;
							}
						}
					}
				}
				.improve-biom{
					margin-top: 0.6rem;
					
					.c-danger{
						color: #FF3131;
					}
					h3{
						margin-bottom: 0.06rem;
					}
					.improve-table{
						width: 100%;
						
						@media screen and (max-width: 374px){
							overflow-x: auto;
						}
					}
					table{
						$border2: 0.01rem solid #F5F5F5;
						font-size: 0.2rem;
						margin-top: 0.28rem;
						margin-bottom: 0.1rem;
						border: $border2;
						
						td{
							padding: 0.2rem 0.15rem;
						}
						li{
							padding: 0.2rem 0;
							border-bottom: $border2;
						}
						thead td{
							padding: 0.2rem 0.15rem;
							border-bottom: $border2;
						}
						tr:not(:last-child) td{
							border-bottom: $border2;
						}
						tr>td.left-side{
							width: 0.42rem;
							padding: 0.1rem;
							border-right: $border2;
						}
						.iconfont{
							margin-right: 0.04rem;
						}
					}
					.iconfont{
						font-size: 0.2rem;
					}
					.symptom{
						.iconfont{
							margin-right: 0.5rem;
						}
					}
				}
			}
		}
	}
	
.branch {
	width: 100%;
	padding: 0.24rem;
	background-color: #fff;
}
.branch_score {
	position: relative;
	margin-bottom: 0.16rem;
}
.branch_score .branch_content {
	padding-top: 0.88rem;
	border-radius: 0.12rem;
	
	.arrow{
		position: absolute;
		top: -0.02rem;
		left: 0.86rem;
		z-index: 4;
		width: 0.4rem;
		height: 0.4rem;
		background-color: #fff;
		transform: rotateZ(45deg)translateX(-50%);
	}
}
.branch_item_title {
	position: absolute;
	top: 0;
	left: -0.02rem;
	display: flex;
	align-items: center;
	width: 2.2rem;
	height: 0.66rem;
	padding-bottom: 0.08rem;
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.32rem;
	padding: 0 0.24rem 0.08rem;
	color: #fff;
	background: url(../../assets/images/result_score_title.png);
	background-size: 100% 100%;
	// box-shadow: 0px -2px 6px rgba(56, 81, 130, 0.25) inset;
}
.branch_sangao_title {
	position: absolute;
	top: 0;
	left: -0.02rem;
	display: flex;
	align-items: center;
	width: 4.9rem;
	height: 0.66rem;
	padding-bottom: 0.08rem;
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.32rem;
	padding: 0 0.24rem 0.08rem;
	color: #fff;
	background: url(../../assets/images/score_title_bg2.png);
	background-size: 100% 100%;
}
.branch_dl_title {
	position: absolute;
	top: 0;
	left: -0.02rem;
	display: flex;
	align-items: center;
	width: 5.8rem;
	height: 0.66rem;
	padding-bottom: 0.08rem;
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.32rem;
	padding: 0 0.24rem 0.08rem;
	color: #fff;
	background: url(../../assets/images/score_title_bg3.png);
	background-size: 100% 100%;
}
.score_info {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 1.30rem;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.28rem;
	background: rgba(242, 247, 255, 1);
	border-radius: 0.12rem;
}
.score_info_top {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 1.30rem;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.28rem;
	background: rgba(242, 247, 255, 1);
	border-radius: 0.12rem;
	text-align: left;
	padding: 0.24rem 0.32rem;
}
.score_info_top::before {
	content: "";  
	width: 0;  
	height: 0;  
	border: 0.2rem solid rgba(242, 247, 255, 1);
	border-color: rgba(242, 247, 255, 1) transparent transparent transparent;
	position:absolute;  
	bottom: -0.36rem;  
	left: var(--infoLeft);
}
.score_info_top_info {
	display: flex;
	align-items: flex-end;
}
.score_info_top_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.36rem;
	height: 0.56rem;
	border-radius: 0.40rem;
	color: rgba(255, 255, 255, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.22rem;
	background: linear-gradient(5.18deg, rgba(102, 129, 250, 1) 3.09%, rgba(148, 168, 255, 1) 95.17%);
}
.score_info::before {
	content: "";  
	width: 0;  
	height: 0;  
	border: 0.2rem solid rgba(242, 247, 255, 1);
	border-color: rgba(242, 247, 255, 1) transparent transparent transparent;
	position:absolute;  
	bottom: -0.36rem;  
	left: var(--infoLeft);
}
.score_line {
	position: relative;
	width: 100%;
	height: 0.12rem;
	border-radius: 0.5rem;
	margin-top: 0.3rem;
	background-color: rgb(219, 228, 242);
	box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
}
.score_line::before {
	content: '0';
	position: absolute;
	top: 0.2rem;
	left: 0;
	color: rgba(108, 130, 168, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.22rem;
}
.score_one {
	position: absolute;
	width: 59%;
	height: 0.12rem;
	border-radius: 0.5rem 0 0 0.5rem;
	background-color: rgba(254, 90, 110, 1);
	box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
}
.score_one_bg {
	position: absolute;
	width: 59%;
	height: 0.12rem;
	border-radius: 0.5rem 0 0 0.5rem;
	// background-color: rgba(254, 90, 110, 1);
	// box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
	// border-right: 0.08rem solid #fff;
}
.score_one_bg::after {
	content: '59';
	position: absolute;
	top: 0.2rem;
	right: -0.12rem;
	color: rgba(108, 130, 168, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.22rem;
}
.score_line_top {
	width: 100%;
	height: 0.12rem;
	border-radius: 0.5rem;
	background-color: rgb(219, 228, 242);
	box-shadow: 0 0.2rem 0.2rem rgba(81, 159, 232, 0.15) inset;
}
.score_d1 {
	position: absolute;
	left: 58%;
	width: 0.08rem;
	height: 0.12rem;
	background-color: #fff;
	z-index: 99;
}
.score_d2 {
	position: absolute;
	left: 88%;
	width: 0.08rem;
	height: 0.12rem;
	background-color: #fff;
	z-index: 99;
}
.score_circle1 {
	position: absolute;
	top: -0.06rem;
	left: 0;
	width: 0.22rem;
	height: 0.22rem;
	border-radius: 50%;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 0.02rem 0.04rem rgba(165, 209, 149, 1);
	z-index: 100;
}
.score_circle2 {
	position: absolute;
	top: -0.06rem;
	left: 0;
	width: 0.22rem;
	height: 0.22rem;
	border-radius: 50%;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 0.02rem 0.04rem rgba(223, 202, 149, 1);
	z-index: 100;
}
.score_circle3 {
	position: absolute;
	top: -0.06rem;
	left: 0;
	width: 0.22rem;
	height: 0.22rem;
	border-radius: 50%;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0 0.02rem 0.04rem rgba(223, 171, 154, 1);
	z-index: 100;
}
.score_two {
	position: absolute;
	width: 30%;
	left: 59%;
	height: 0.12rem;
	// border-radius: 0.5rem 0 0 0.5rem;
	background-color: rgba(255, 204, 26, 1);
	box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
	// border-right: 0.08rem solid #fff;
}
.score_two_bg {
	position: absolute;
	width: 30%;
	left: 59%;
	height: 0.12rem;
}
.score_two_bg::after {
	content: '89';
	position: absolute;
	top: 0.2rem;
	right: -0.12rem;
	color: rgba(108, 130, 168, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.22rem;
}
.score_three_bg {
	position: absolute;
	width: 11%;
	left: 89%;
	height: 0.12rem;
	border-radius: 0 0.5rem 0.5rem 0;
	// background-color: rgb(31, 211, 169);
	// box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
}
.score_three {
	position: absolute;
	width: 11%;
	left: 89%;
	height: 0.12rem;
	border-radius: 0 0.5rem 0.5rem 0;
	background-color: rgb(31, 211, 169);
	box-shadow: 0 0 0.05rem rgba(171, 245, 255, 0.5) inset;
}
.score_three_bg::after {
	content: '100';
	position: absolute;
	top: 0.2rem;
	right: 0rem;
	color: rgba(108, 130, 168, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.22rem;
}
.score_text {
	color: rgba(106, 113, 128, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.36rem;
	text-align: left;
	margin-top: 0.5rem;
}
.branch_course {
	margin-bottom: 0.16rem;
}
.branch_header {
	width: 100%;
	height: 0.92rem;
	color: rgba(87, 93, 105, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.3rem;
	display: flex;
	align-items: center;
	padding: 0 0.24rem;
	border-radius: 0.12rem 0.12rem 0 0;
	background: linear-gradient(90.04deg, rgba(238, 245, 255, 1) 0.05%, rgba(248, 251, 255, 0) 100.07%);
}
.branch_content {
	position: relative;
	width: 100%;
	padding: 0.24rem;
	border-radius: 0 0 0.12rem 0.12rem;
	border: 1px solid rgba(211, 213, 222, 1);
	box-sizing: border-box;
	background: rgba(255, 255, 255, 1);
}
.branch_type_list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.12rem;
}
.branch_type_item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.75rem;
	height: 0.76rem;
	color: rgba(102, 129, 250, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.28rem;
	border: 1px solid rgba(167, 183, 255, 1);
	border-radius: 0.60rem;
}
.branch_type_jt {
	width: 0.43rem;
	height: 0.18rem;
	background: url(../../assets/images/branch_jt.png) no-repeat;
	background-size: 100% 100%;
}
.branch_100 {
	width: 100%;
}
.branch_type_text {
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.44rem;
	text-align: left;
	margin-top: 0.16rem;
}
.branch_type_c {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.2rem;
	height: 1.2rem;
	padding: 0 0.2rem;
	color: rgba(102, 129, 250, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.28rem;
	line-height: 0.32rem;
	border: 1px solid rgba(167, 183, 255, 1);
	border-radius: 50%;
}
.branch_yl {
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.44rem;
	margin-bottom: 0.2rem;
	text-align: left;
}
.branch_yl span, .branch_yjk span{
	color: rgba(102, 129, 250, 1);
	font-family: YouSheBiaoTiHei;
	font-size: 0.32rem;
	line-height: 0.44rem;
	font-weight: bold;
}
.branch_yjk {
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.44rem;
	margin-bottom: 0.2rem;
	text-align: left;
}
.z_index9 {
	z-index: 101;
	margin-left: 0.1rem;
}
.score_tip_box {
	width: 6.1rem;
	left: -0.78rem;
	top: 0.6rem;;
	padding: 0.24rem;
	color: rgba(59, 69, 73, 1);
	font-weight: 400;
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.44rem;
	border-radius: 0.12rem;
	box-shadow: 0.06rem 0.06rem 0.16rem rgba(47, 68, 87, 0.15);
	text-align: left;
}
.score_tip_span{
	display: block;
	font-weight: 500;
	color: rgba(59, 69, 73, 1);
	margin-top: 0.2rem;
}
.branch_wrap {
	padding: 0 0.24rem;
}
.branch_health_list {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.branch_health_item {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 2.1rem;
	height: 1.18rem;
	color: rgba(119, 127, 143, 1);
	font-family: PingFang SC;
	font-size: 0.3rem;
	border-radius: 0.08rem;
	// border: 1px solid rgba(211, 213, 222, 1);
	// background: url(../../assets/images/branch_health_3.png) no-repeat;
	background: url(../../assets/images/branch_health_bg.png) no-repeat;
	background-size: 99.9% 99.9%;
}
.branch_health_item_ca {
	font-size: 0.24rem;
}
.branch_health_1 {
	width: 2.1rem;
	height: 1.18rem;
	color: rgb(20, 203, 160);
	background: url(../../assets/images/branch_health_1.png) no-repeat;
	background-size: 100% 100%;
}
.branch_health_2 {
	width: 2.1rem;
	height: 1.18rem;
	color: rgb(245, 162, 0);
	background: url(../../assets/images/branch_health_2.png) no-repeat;
	background-size: 100% 100%;
}
.branch_health_3 {
	width: 2.1rem;
	height: 1.18rem;
	color: rgba(250, 102, 102, 1);
	background: url(../../assets/images/branch_health_3.png) no-repeat;
	background-size: 100% 100%;
}
.branch_health_item_text {
	flex: 1;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.28rem;
	margin-top: 0.2rem;
}
.branch_health_scale {
	transform: scale(1.12);
	font-weight: 500;
}

.score_val {
	font-family: Helvetica;
	font-weight: bold;
	font-size: 0.48rem;
}
.xiao_icon {
	width: 0.56rem;
	height: 0.48rem;
}
.score_color1 {
	color: rgb(20, 203, 160);
}
.score_color2 {
	color: rgb(254, 152, 0);
}
.score_color3 {
	color: rgb(250, 102, 102);
}

.sangao_text {
	width: 100%;
	background: rgba(245, 248, 254, 1);
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.28rem;
	line-height: 0.44rem;
	padding: 0.24rem;
	border-radius: 0.08rem;
	text-align: left;
}
.sel_cell {
	text-align: right;
	margin-top: 0.3rem;
}
.select_style {
	width: 3.2rem;
	height: 0.64rem;
	padding-left: 0.24rem;
	border-radius: 0.08rem;
	border: 1px solid rgba(211, 213, 222, 1);
	background-color: #fff;
}
.dl_bg {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl1.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg2 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl2.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg3 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl3.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg4 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl4.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg5 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl5.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg6 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl6.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg7 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl7.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg8 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl8.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg9 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl9.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg10 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl10.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg11 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl11.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg12 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl12.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg13 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl13.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg14 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl14.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg15 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl15.png) no-repeat;
	background-size: 100% 100%;
}
.dl_bg16 {
	position: relative;
	width: 100%;
	height: 4.16rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/dl16.png) no-repeat;
	background-size: 100% 100%;
}
.medical_dl_bg {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/medical_dl.png) no-repeat;
	background-size: 100% 100%;
}
.medical_dl_bg2 {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/medical_dl2.png) no-repeat;
	background-size: 100% 100%;
}
.medical_dl_bg3 {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/medical_dl3.png) no-repeat;
	background-size: 100% 100%;
}
.medical_dl_bg4 {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/medical_dl4.png) no-repeat;
	background-size: 100% 100%;
}

.injury_dl_bg {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/death_dl.png) no-repeat;
	background-size: 100% 100%;
}
.injury_dl_bg2 {
	position: relative;
	width: 100%;
	height: 3.44rem;
	margin-top: 0.2rem;
	background: url(../../assets/images/injury_dl.png) no-repeat;
	background-size: 100% 100%;
}
.dl_catg {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.2rem;
}
.dl_catg_m {
	position: relative;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.22rem;
	line-height: 0.24rem;
}
.dl_catg_wm {
	position: relative;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.22rem;
	line-height: 0.24rem;
	margin-right: 0.8rem;
}
.dl_catg_h {
	position: relative;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.22rem;
	line-height: 0.24rem;
	margin-left: 0.8rem;
}
.dl_catg_m::before {
	content: '';
	position: absolute;
	top: 0.1rem;
	left: -0.3rem;
	width: 0.23rem;
	height: 0.02rem;
	background-color: rgba(91, 155, 213, 1);
}
.dl_catg_wm::before {
	content: '';
	position: absolute;
	top: 0.1rem;
	left: -0.3rem;
	width: 0.23rem;
	height: 0.02rem;
	background-color: rgba(237, 125, 49, 1);
}
.dl_catg_h::before {
	content: '';
	position: absolute;
	top: 0.1rem;
	left: -0.3rem;
	width: 0.23rem;
	height: 0.02rem;
	background-color: #a8a8a8;
}
.dl_bg_line {
	position: absolute;
	width: 0.02rem;
	height: 4.16rem;
	background-color: rgba(211, 213, 222, 1);	
}
.medical_dl_bg_line {
	position: absolute;
	width: 0.02rem;
	height: 3.44rem;
	background-color: rgba(211, 213, 222, 1);
}
.dl_bg_tip {
	position: absolute;
	top: 60%;
	left: -0.7rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.44rem;
	height: 0.72rem;
	border-radius: 0.08rem;
	color: rgba(87, 93, 105, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0.02rem 0.02rem 0.08rem rgba(79, 92, 139, 0.25);
}
.dl_bg_tip span {
	position: relative;
	z-index: 9;
}
.to_update {
	position: absolute;
	right: 0.24rem;
	top: 0.24rem;
	color: rgba(87, 93, 105, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	line-height: 0.24rem;
}
.text_left {
	text-align: left;
}
.text_right {
	text-align: right;
}

.body_item {
	width: 100%;
	padding: 0.24rem 0.28rem;
	border-radius: 0.16rem;
	background: rgba(255, 255, 255, 1);
	margin-bottom: 0.16rem;
}
.body_item_title {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 600;
	font-size: 0.32rem;
	margin-bottom: 0.2rem;
}
.body_item_val {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-size: 0.24rem;
	font-weight: 400;
}
.body_item_val span {
	color: rgba(50, 54, 62, 1);
	font-family: MiSans-Demibold;
	font-size: 0.32rem;
	font-weight: 500;
}
.body_item_group {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 0.24rem;
}
.body_item_group span {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.32rem;
}
.body_item_group_db {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 0.24rem;
	margin-top: 0.08rem;
}
.body_item_group_db span {
	display: inline-block;
	// color: rgba(239, 143, 0, 1);
	font-family: PingFang SC;
	font-weight: bold;
	font-size: 0.24rem;
	border-radius: 0.08rem;
	padding: 0.04rem 0.12rem;
}
.group_di {
	color: rgba(239, 143, 0, 1) !important;
	background: rgba(255, 233, 163, 1) !important;
}
.group_zhong {
	color: rgba(35, 203, 41, 1) !important;
	background-color: rgba(213, 244, 214, 1) !important;
}
.group_gao {
	color: rgba(239, 143, 0, 1) !important;
	background: rgba(255, 233, 163, 1) !important;
}
.body_item_oth {
	width: 100%;
	padding: 0.24rem 0.18rem 0.24rem 0.28rem;
	border-radius: 0.16rem;
	background: rgba(255, 255, 255, 1);
	margin-bottom: 0.16rem;
}
.body_item_oth .body_item_group{
	margin-top: 0.11rem;
	font-size: 0.28rem;
}
.body_item_oth .body_item_val {
	margin-top: 0.08rem;
	font-size: 0.28rem;
}
.body_item_oth_val {
	color: rgba(102, 129, 250, 1);
	font-family: MiSans-Demibold;
	font-size: 0.4rem;
	line-height: 0.4rem;
	font-weight: bold;
	margin-top: 0.24rem;
	margin-bottom: 0.28rem;
}
.body_item_oth .body_item_val span {
	display: inline-block;
	// color: rgba(239, 143, 0, 1);
	font-family: PingFang SC;
	font-weight: bold;
	font-size: 0.24rem;
	border-radius: 0.08rem;
	padding: 0.04rem 0.12rem;
	margin-left: 0.08rem;
}
.body_item_oth p {
	color: rgba(140, 145, 154, 1);
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 0.28rem;
	line-height: 0.44rem;
}


.body_echat {
	display: flex;
	align-items: center;
	margin-top: 0.48rem;
	padding-bottom: 0.3rem;
	margin-bottom: 0.4rem;
	border-bottom: 1px solid rgba(245, 246, 250, 1);
}

.body_echat_title {
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 0.24rem;
	margin-right: 0.3rem;
}

.line {
	position: relative;
	display: flex;
	align-items: center;
}
.biaoqian_box {
	position: absolute;
	top: -0.02rem;
	left: 0;
	z-index: 9;
}
.biaoqian {
	width: 0.16rem;
	height: 0.25rem;
	// transform: rotate(90deg);
}
// .line::after {
// 	position: absolute;
// 	content: '';
// 	top: -0.1rem;
// 	left: 20%;
// 	width: 0.22rem;
// 	height: 0.12rem;
// 	background: url(../../assets/images/biaoqian.png) no-repeat;
// 	background-size: 100% 100%;
// 	transform: rotate(90deg);
// }
.line_item {
	position: relative;
	width: 1.52rem;
	height: 0.12rem;
	border-radius: 0.06rem;
}
.whr_wrap .line_item {
	width: 2.28rem;
}
.body_val_text {
	position: relative;
	top: 0.10rem;
	right: -0.16rem;
	text-align: right;
	color: rgba(50, 54, 62, 1);
	font-family: PingFang SC;
	font-weight: 500;
	font-size: 0.24rem;
}
.line_di {
	background-color: rgba(244, 205, 64, 1);
}
.line_zhong {
	background-color: rgba(63, 216, 69, 1);
}
.line_gao {
	background-color: rgba(255, 92, 92, 1);
}
.body_val_di {
	color: rgba(239, 143, 0, 1);
	background-color: rgba(255, 233, 163, 1);
}
.body_val_zhong {
	color: rgba(35, 203, 41, 1);
	background-color:rgba(213, 244, 214, 1);
}
.body_val_gao {
	color: rgba(250, 81, 81, 1);
	background-color: rgba(255, 199, 199, 1);
}

.body_echat_val {
	position: absolute;
	top: -0.64rem;
	left: 0;
	color: rgba(50, 54, 62, 1);
	font-family: MiSans-Demibold;
	font-size: 0.32rem;
	font-weight: bold;
	display: flex;
	align-items: center;
}
.body_echat_val span {
	display: inline-block;
	padding: 0.04rem 0.12rem;
	font-family: PingFang SC;
	font-weight: bold;
	font-size: 0.24rem;
	border-radius: 0.08rem;
	margin-left: 0.08rem;
}
.costs_top {
	position: relative;
	color: rgba(41, 48, 51, 1);
	font-family: PingFang SC;
	font-weight: 400;
	font-size: 0.36rem;
	line-height: 0.36rem;
	text-align: left;
	margin-bottom: 0.24rem;
}
.costs_top::before {
	content: '';
	position: absolute;
	top: 0.12rem;
	left: -0.24rem;
	width: 0.16rem;
	height: 0.12rem;
	border-radius: 0 0.04rem 0.04rem 0;
	background: rgba(104, 131, 251, 1);
}

.costs_top .bomi_btn_cell{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1,
}
.bomi_btn_cell .bomi_v_btn {
	padding: 0 0.1rem;
	font-size: 0.18rem;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #6883FB;
	border-radius: 0.04rem;
	border: 0.01rem solid #6883FB;
}
.bomi_btn_cell .bomi_g_btn {
	padding: 0 0.1rem;
	font-size: 0.18rem;
	font-family: PingFang SC-Regular, PingFang SC;
	font-weight: 400;
	color: #6883FB;
	border-radius: 0.04rem;
	border: 0.01rem solid #6883FB;
	margin-left: 0.08rem;
}
.bomi_btn_cell .bomi_btn_active {
	background-color: #6883FB;
	color: #fff;
}
.bomi_btn_box {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.margin-top-48 {
	margin-top: 0.48rem;
}

.biom-chart_bottom {
	position: relative;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.26rem;
	line-height: 0.34rem;
	padding-left: 1.4rem;
	background-color: #F5F8FC;
}
.biom-chart_bottom::before {
	content: '';
	position: absolute;
	top: 0.08rem;
	left: 1.1rem;
	width: 0.16rem;
	height: 0.16rem;
	border-radius: 0.04rem;
	background-color: rgba(255, 91, 91, 1);
	
}
.biom-chart_bottom2 {
	position: relative;
	color: rgba(59, 69, 73, 1);
	font-family: PingFang SC;
	font-size: 0.26rem;
	line-height: 0.34rem;
	padding-left: 1.4rem;
	background-color: #F5F8FC;
	padding-bottom: 0.2rem;
	padding-top: 0.08rem;
	border-radius: 0 0 0.08rem 0.08rem;
}
.biom-chart_bottom2::before {
	content: '';
	position: absolute;
	top: 0.16rem;
	left: 1.1rem;
	width: 0.16rem;
	height: 0.16rem;
	border-radius: 0.04rem;
	background-color: rgba(255, 204, 26, 1);
	
}
.m14_t {
	width: 1.4rem;
}
.m14_v {
	flex: 1;
}
.m14_l {
	width: 1.2rem;
	text-align: right;
}

.new_bmi {
	margin-bottom: 0.3rem;
}
.new_bmi_p {
	margin-top: 0.2rem;
	line-height: 0.38rem;
}

.new_bmi_val, .new_bmi_b, .new_bmi_p_v, .new_bmi_p_v2, .new_bmi_lx {
	font-size: 0.3rem;
	color: #40444D;
	font-weight: 500;
}

.improve_c0 {
	color: rgb(254, 152, 0) !important;
}
.eat b{
	color: #6883FB !important;
}


.weight_chart {
	width: 100%;
	height: 4rem;
}
.whr_chart {
	width: 100%;
	height: 4rem;
}

.weight_chart_title {
	font-size: 0.14rem;
	font-family: PingFang SC-Medium, PingFang SC;
	font-weight: 400;
	color: #40444D;
	line-height: 0.18rem;
	margin-top: 0.3rem;
}
</style>
